<template>
	<div>
		<v-row>
			<v-col cols="3" v-if="size > 960" class="pt-0">
				<events
					@changeEvent="changeEvent"
					@openChannel="openCanales"
					:hipodromos="hipodromos"
					:galgos="galgos"
					:carretas="carretas"
				></events>
				<br />
				<br />
				<!-- <v-btn color="primary" block class="text-end select-event px-4" x-large dark>
					<div class="d-flex align-center mr-auto">
						<v-img src="../assets/img/hipo.svg" aspect-ratio="1" width="32px"></v-img>
						<span class="ml-3">EVENTOS</span>
					</div>
					<v-icon class>mdi-menu-down</v-icon>
				</v-btn>
				<v-list two-line flat color="transparent" v-if="selectedEventType == 'HIPODROMOS'">
					<v-list-item
						v-for="item in hipodromos"
						:key="item.nombre"
						class="event-item mb-1 py-0"
						@click="changeEvent(item,true)"
					>
						<v-list-item-avatar size="32">
							<v-img src="../assets/img/hipo.svg"></v-img>
						</v-list-item-avatar>
						<v-list-item-content class="py-0">
							<v-list-item-title>
								<b style="font-size:14px">{{item.nombre}}</b>
							</v-list-item-title>
							<v-list-item-subtitle>
								<small>
									<b>CARRERA {{item.idcarrera}}</b>
								</small>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action>
							<v-chip class small dark label color="#00afef" v-if="item.estatus == 'ABIERTA'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
							<v-chip class small label color="#ffc107" v-if="item.estatus == 'CORRIENDO'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
							<v-chip class small dark label color="#eb262c" v-if="item.estatus == 'CERRADA'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
						</v-list-item-action>
					</v-list-item>
					<v-list-item
						v-for="item in galgos"
						:key="item.nombre"
						class="event-item mb-1 py-0"
						@click="changeEvent(item,true)"
					>
						<v-list-item-avatar size="32">
							<v-img src="../assets/img/galgos.svg"></v-img>
						</v-list-item-avatar>
						<v-list-item-content class="py-0">
							<v-list-item-title>
								<b style="font-size:14px">{{item.nombre}}</b>
							</v-list-item-title>
							<v-list-item-subtitle>
								<small>
									<b>CARRERA {{item.idcarrera}}</b>
								</small>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action>
							<v-chip class small dark label color="#00afef" v-if="item.estatus == 'ABIERTA'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
							<v-chip class small label color="#ffc107" v-if="item.estatus == 'CORRIENDO'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
							<v-chip class small dark label color="#eb262c" v-if="item.estatus == 'CERRADA'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
						</v-list-item-action>
					</v-list-item>
					<v-list-item
						v-for="item in carretas"
						:key="item.title"
						@click="changeEvent(item,true)"
						class="event-item mb-1 py-0"
					>
						<v-list-item-avatar size="32">
							<v-img src="../assets/img/carretas.svg"></v-img>
						</v-list-item-avatar>
						<v-list-item-content class="py-0">
							<v-list-item-title>
								<b style="font-size:14px">{{item.nombre}}</b>
							</v-list-item-title>
							<v-list-item-subtitle>
								<small>
									<b>CARRERA {{item.idcarrera}}</b>
								</small>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action>
							<v-chip class small dark label color="#00afef" v-if="item.estatus == 'ABIERTA'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
							<v-chip class small label color="#ffc107" v-if="item.estatus == 'CORRIENDO'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
							<v-chip class small dark label color="#eb262c" v-if="item.estatus == 'CERRADA'">
								<small>
									<b>{{item.estatus}}</b>
								</small>
							</v-chip>
						</v-list-item-action>
					</v-list-item>
				</v-list>
				<div v-if="noEvents" style="background:#fff;border-radius:4px" class="pa-4">
					<b style="color:#000">No hay eventos en este momento.</b>
				</div>-->
			</v-col>
			<v-col class="px-0 pt-0" cols="12" sm="12" md="9">
				<event-details
					:loading="loadingTable"
					:event="selectedEvent"
					:races="races"
					:gameType="gameType"
					:selectedGameType="selectedGameType"
					:selectedAmout="selectedAmout"
					:amount="amount"
					:jugada="jugada"
					:jugada2="jugada2"
					:jugada3="jugada3"
					:jugada4="jugada4"
					:total="GPS.monto"
					:canal="canal"
					@changeRace="changeRace"
					@changeGameType="changeGameType"
					@changeAmount="changeAmount"
					@cleanJugada="cleanJugada"
					@jugar="dialogTicket = true"
					@canales="oCanales"
				></event-details>

				<!-- <div
					:class="`${size  > 960 ? ' ':'event-details-m '} py-1 d-block  px-3 mb-1 mb-lg-2`"
				>
					<b v-if="size > 960" class="event-title">{{selectedEvent.nombre}}</b>
					<v-divider
						v-if="size > 960"
						vertical
						class="my-auto mx-2"
						style="border-width: 0 thin 0 1px;min-height:24px;height:10px"
						dark
					></v-divider>
					<v-menu offset-y>
						<template v-slot:activator="{ on }">
							<v-btn text class="px-0 mx-0 mx-lg-2"  v-on="on">
								Carrera {{selectedEvent.idcarrera}}
								<v-icon class>mdi-menu-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-for="(item, index) in races" :key="index" @click="changeRace(item,true)">
								<v-list-item-title class="d-flex">
									<small class="mr-2">
										<b>CARRERA {{ item.idcarrera }}</b>
									</small>
									<v-chip
										class="ml-auto"
										x-small
										dark
										label
										color="#00afef"
										v-if="item.estatus == 'ABIERTA'"
									>
										<b>{{item.estatus}}</b>
									</v-chip>
									<v-chip class="ml-auto" x-small label color="#ffc107" v-if="item.estatus == 'CORRIENDO'">
										<b>{{item.estatus}}</b>
									</v-chip>
									<v-chip
										class="ml-auto"
										x-small
										dark
										label
										color="#eb262c"
										v-if="item.estatus == 'CERRADA'"
									>
										<b>{{item.estatus}}</b>
									</v-chip>
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-divider
						vertical
						class="my-auto mx-2"
						style="border-width: 0 thin 0 1px;min-height:24px;height:10px"
						dark
					></v-divider>
					<v-menu offset-y v-if="size < 960">
						<template v-slot:activator="{ on }">
							<v-btn text class="px-0 mx-auto" dark v-on="on">
								{{selectedGameType}}
								<v-icon class="pr-0 mr-0">mdi-menu-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-for="(item, index) in gameType" :key="index" @click="changeGameType(item)">
								<v-list-item-title class="d-flex">
									<small class="mr-2">
										<b>{{ item }}</b>
									</small>
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-divider
						v-if="size < 960"
						vertical
						class="my-auto mx-2"
						style="border-width: 0 thin 0 1px;min-height:24px;height:10px"
						dark
					></v-divider>

					<v-menu offset-y v-if="size < 960">
						<template v-slot:activator="{ on }">
							<v-btn text class="px-0" dark v-on="on">
								UND {{selectedAmout}}
								<v-icon class>mdi-menu-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-for="(item, index) in amount" :key="index" @click="changeAmount(item)">
								<v-list-item-title class="d-flex">
									<small class="mr-2">
										<b>UND {{ item }}</b>
									</small>
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<div v-if="size > 960">
						<v-chip small dark label color="#00afef" v-if="selectedEvent.estatus == 'ABIERTA'">
							<b>{{selectedEvent.estatus}}</b>
						</v-chip>
						<v-chip small label color="#ffc107" v-if="selectedEvent.estatus == 'CORRIENDO'">
							<b>{{selectedEvent.estatus}}</b>
						</v-chip>
						<v-chip small dark label color="#eb262c" v-if="selectedEvent.estatus == 'CERRADA'">
							<b>{{selectedEvent.estatus}}</b>
						</v-chip>
					</div>
				</div>-->
				<v-row v-if="pick" no-gutters>
					<v-col cols="12">
						<div style>
							<v-simple-table
								:dark="mode"
								class="horse"
								fixed-header
								:height="size > 960 ? selectedEvent.estatus == 'CERRADA'?'calc(100vh - 222px)':'calc(100vh - 180px)':bSheet?'calc(100vh - 284px)':selectedEvent.estatus == 'CERRADA'?'calc(100vh - 208px)':'calc(100vh - 164px)'"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="pt-0 text-center" style="background:white!important">
												<b></b>
											</th>
											<th class="pt-0 text-left" style="background:white!important">
												<b></b>
											</th>
											<th class="pt-0 text-left" style="background:white!important" v-if="size > 960">
												<b></b>
											</th>
											<th class="pt-0 text-left" style="background:white!important">
												<b></b>
											</th>
											<th
												class="text-center"
												style="height:31px;padding: 0 0px;background:white!important"
												v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div class style="position:relative;">
													<v-btn
														:color="pickRace == tableIdx ?'primary':'#22344f'"
														depressed
														dark
														x-small
														class="px-0"
														rounded
														style="min-width: 30px;min-height: 30px;"
														@click="changePickRace(tableIdx)"
													>
														<span style="position:relative;top:-2px">C{{tableIdx}}</span>
													</v-btn>
												</div>
											</th>
											<th
												class="text-center"
												style="height:31px;padding: 0 0px;background:white!important"
												v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div style="position:">
													<v-btn
														@click="changePickRace(tableIdx+1)"
														:color="pickRace == tableIdx+1 ?'primary':'#22344f'"
														depressed
														x-small
														class="px-0"
														dark
														rounded
														style="min-width: 30px;min-height: 30px;"
													>
														<span style="position:relative;top:-2px">C{{tableIdx+1}}</span>
													</v-btn>
												</div>
											</th>
											<th
												class="text-center"
												style="height:31px;padding: 0 0px;background:white!important"
												v-if="selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div style="position:relative;">
													<v-btn
														@click="changePickRace(tableIdx+2)"
														:color="pickRace == tableIdx+2 ?'primary':'#22344f'"
														depressed
														x-small
														class="px-0"
														dark
														rounded
														style="min-width: 30px;min-height: 30px;"
													>
														<span style="position:relative;top:-2px">C{{tableIdx+2}}</span>
													</v-btn>
												</div>
											</th>
											<th
												class="text-center"
												style="height:31px;padding: 0 0px;background:white!important"
												v-if="selectedGameType == 'PICK 4'"
											>
												<div style="position:relative;">
													<v-btn
														@click="changePickRace(tableIdx+3)"
														:color="pickRace == tableIdx+3 ?'primary':'#22344f'"
														depressed
														x-small
														class="px-0"
														rounded
														dark
														style="min-width: 30px;min-height: 30px;"
													>
														<span style="position:relative;top:-2px">C{{tableIdx+3}}</span>
													</v-btn>
												</div>
											</th>
										</tr>
									</thead>

									<thead>
										<tr>
											<th class="pt-0 text-left">
												<b>Gate</b>
											</th>
											<th class="pt-0 text-left">
												<b>Nombre</b>
											</th>
											<th class="pt-0 text-left" v-if="size > 960">
												<b>Jockey</b>
											</th>
											<th class="pt-0 text-left">
												<b>ML</b>
											</th>
											<th
												class="text-center"
												style="padding: 0 12px;"
												v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
												@mouseover="changePickRace(tableIdx,true);c=true"
												@mouseout="c=false"
											>
												<div class style="position:relative;top:-1px;background">
													<div class="round todo">
														<input
															:disabled="selectedEvent.estatus=='CERRADA'"
															@click="selectAllJugada(1)"
															v-model="allJugadaSelected"
															type="checkbox"
															id="jugada"
														/>
														<label
															for="jugada"
															:style="pickRace != tableIdx ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
														>
															<small v-if="!allJugadaSelected" class="todo-text">TODO</small>
															<small v-else class="todo-text active">TODO</small>
														</label>
													</div>
												</div>
											</th>
											<th
												@mouseover="changePickRace(tableIdx+1,true);c=true"
												@mouseout="c=false"
												class="text-center"
												style="padding: 0 12px;"
												v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div
													style="position:relative;top:-1px"
													v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
												>
													<div class="round todo">
														<input
															:disabled="selectedEvent.estatus=='CERRADA'"
															@click="selectAllJugada(2)"
															v-model="allJugada2Selected"
															type="checkbox"
															id="jugada2"
														/>
														<label
															for="jugada2"
															:style="pickRace != tableIdx+1 ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
														>
															<small v-if="!allJugada2Selected" class="todo-text">TODO</small>
															<small v-else class="todo-text active">TODO</small>
														</label>
													</div>
												</div>
											</th>
											<th
												@mouseover="changePickRace(tableIdx+2,true);c=true"
												@mouseout="c=false"
												style="padding: 0 12px;"
												class="text-center"
												v-if="selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div style="position:relative;top:-1px">
													<div class="round todo">
														<input
															:disabled="selectedEvent.estatus=='CERRADA'"
															@click="selectAllJugada(3)"
															v-model="allJugada3Selected"
															type="checkbox"
															id="jugada3"
														/>
														<label
															for="jugada3"
															:style="pickRace != tableIdx+2 ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
														>
															<small v-if="!allJugada3Selected" class="todo-text">TODO</small>
															<small v-else class="todo-text active">TODO</small>
														</label>
													</div>
												</div>
											</th>
											<th
												@mouseover="changePickRace(tableIdx+3,true);c=true"
												@mouseout="c=false"
												style="padding: 0 12px;"
												class="text-center"
												v-if="selectedGameType == 'PICK 4'"
											>
												<div style="position:relative;top:-1px" v-if="selectedGameType == 'PICK 4'">
													<div class="round todo">
														<input
															:disabled="selectedEvent.estatus=='CERRADA'"
															@click="selectAllJugada(4)"
															v-model="allJugada4Selected"
															type="checkbox"
															id="jugada4"
														/>
														<label
															for="jugada4"
															:style="pickRace != tableIdx+3 ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
														>
															<small v-if="!allJugada4Selected" class="todo-text active">TODO</small>
															<small v-else class="todo-text">TODO</small>
														</label>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody v-if="loadingTable">
										<tr>
											<td
												:colspan="size > 960? 5:4"
												:style="size > 960?'height: calc(100vh - 308px)':'height: calc(100vh - 218px)'"
											>
												<div class="d-flex">
													<v-btn text color="white" depressed loading class="mx-auto">Cargando</v-btn>
												</div>
											</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr
											v-for="(horse,idx) in horsesTable"
											:key="idx"
											:style="typeof horses[pickRace] !== 'undefined' && idx <= horses[pickRace].length-1 ? (horses[pickRace][idx].estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horses[pickRace][idx].estatus_ejemplar == 'RETIRADO' ? '':'' :selectedEvent.estatus == 'CERRADA'?'opacity:.64':''"
											:class="typeof horses[pickRace] !== 'undefined' && idx <= horses[pickRace].length-1 ? horses[pickRace][idx].estatus_ejemplar == 'RETIRADO' ? 'retired':'':''"
										>
											<td style>
												<template v-if="idx <= horses[pickRace].length-1">
													<img
														style="border-radius:50%;opacity:.84"
														:src="'https://www.trackinfo.com/'+horses[pickRace][idx].numero"
														alt
														srcset
													/>
												</template>
											</td>
											<td style="font-weight:500">
												<template
													v-if="idx <= horses[pickRace].length-1"
												>{{ horses[pickRace][idx].nombre_ejemplar}}</template>
											</td>
											<td v-if="size > 960">
												<template v-if="idx <= horses[pickRace].length-1">{{ horses[pickRace][idx].jinete}}</template>
											</td>
											<td>
												<template v-if="idx <= horses[pickRace].length-1">
													<b>{{ horses[pickRace][idx].ml}}</b>
												</template>
											</td>
											<td
												class="check"
												style="width:1px;padding: 0 12px;"
												v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div class="d-flex">
													<template v-if="idx <= horses[tableIdx].length-1">
														<div class="d-flex" v-if="horses[tableIdx][idx].estatus_ejemplar == 'ACTIVO'">
															<div class>
																<div class="round">
																	<input
																		v-model="jugada"
																		type="checkbox"
																		:value="horses[tableIdx][idx].idejemplares"
																		:id="'checkbox'+horses[tableIdx][idx].idejemplares+'pick'"
																		:disabled="(horses[tableIdx][idx].estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horses[tableIdx][idx].estatus_ejemplar == 'RETIRADO'"
																	/>
																	<label
																		@mouseover="changePickRace(tableIdx,true);c=true"
																		@mouseout="c=false"
																		:for="'checkbox'+horses[tableIdx][idx].idejemplares+'pick'"
																		:style="pickRace != tableIdx ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
																	></label>
																</div>
															</div>
														</div>
														<template v-else>
															<div class>
																<div class="round todo">
																	<input disabled type="checkbox" id="retirado" />
																	<label
																		for="retirado"
																		:style="pickRace != tableIdx ?'opacity:.5;background:#eee;':'opacity:.5;'"
																		style="cursor:default;margin: 0 0 0 -3px;background:#eeeeee"
																	>
																		<small style="position:relative;top:5px;z-index:0" class="d-flex">
																			<b class="mx-auto" style="color:black">R</b>
																		</small>
																	</label>
																</div>
															</div>
														</template>
													</template>
													<template v-else>
														<div
															style="opacity:0;"
															v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
														>
															<div class="round todo" style="z-index:-1">
																<input disabled type="checkbox" id="retirado" />
																<label
																	for="retirado"
																	style="cursor: default;margin: 0 0 0 -3px;background:#eeeeee;z-index:-1"
																>
																	<small style="position:relative;top:5px;z-index:0" class="d-flex">
																		<b class="mx-auto" style="color:black">R</b>
																	</small>
																</label>
															</div>
														</div>
													</template>
												</div>
											</td>
											<td
												class="check"
												style="width:1px;padding: 0 12px;"
												v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div class="d-flex">
													<template v-if="idx <= horses[tableIdx+1].length-1">
														<div class="d-flex" v-if="horses[tableIdx+1][idx].estatus_ejemplar == 'ACTIVO'">
															<div>
																<div class="round" v-if="idx <= horses[tableIdx+1].length-1">
																	<input
																		v-model="jugada2"
																		type="checkbox"
																		:value="horses[tableIdx+1][idx].idejemplares"
																		:id="'horse'+horses[tableIdx+1][idx].idejemplares+'pick2'"
																		:disabled="(horses[tableIdx+1][idx].estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horses[tableIdx+1][idx].estatus_ejemplar == 'RETIRADO'"
																	/>
																	<label
																		@mouseover="changePickRace(tableIdx+1,true);c=true"
																		@mouseout="c=false"
																		:for="'horse'+horses[tableIdx+1][idx].idejemplares+'pick2'"
																		:style="pickRace != tableIdx+1 ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
																	></label>
																</div>
															</div>
														</div>
														<template v-else>
															<div>
																<div class="round todo">
																	<input disabled type="checkbox" id="retirado" />
																	<label
																		for="retirado"
																		:style="pickRace != tableIdx ?'opacity:.5;background:#eee;':'opacity:.5;'"
																		style="cursor: default;margin: 0 0 0 -3px;background:#eeeeee"
																	>
																		<small style="position:relative;top:5px;z-index:0" class="d-flex">
																			<b class="mx-auto" style="color:black">R</b>
																		</small>
																	</label>
																</div>
															</div>
														</template>
													</template>
													<template v-else>
														<div
															style="opacity:0"
															v-if="selectedGameType == 'PICK 2' || selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
														>
															<div class="round todo" style="z-index:-1">
																<input disabled type="checkbox" id="retirado" />
																<label for="retirado" style="margin: 0 0 0 -3px;background:#eeeeee;z-index:-1">
																	<small style="position:relative;top:5px;z-index:0" class="d-flex">
																		<b class="mx-auto" style="color:black">R</b>
																	</small>
																</label>
															</div>
														</div>
													</template>
												</div>
											</td>
											<td
												class="check"
												style="width:1px;padding: 0 12px;"
												v-if="selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
											>
												<div class="d-flex">
													<template
														v-if="typeof horses[tableIdx+2] !== 'undefined' && idx <= horses[tableIdx+2].length-1"
													>
														<div class="d-flex" v-if="horses[tableIdx+2][idx].estatus_ejemplar == 'ACTIVO'">
															<div>
																<div class="round" v-if="idx <= horses[tableIdx+2].length-1">
																	<input
																		v-model="jugada3"
																		type="checkbox"
																		:value="horses[tableIdx+2][idx].idejemplares"
																		:id="'horse'+horses[tableIdx+2][idx].idejemplares+'pick3'"
																		:disabled="(horses[tableIdx+2][idx].estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horses[tableIdx+2][idx].estatus_ejemplar == 'RETIRADO'"
																	/>
																	<label
																		@mouseover="changePickRace(tableIdx+2,true);c=true"
																		@mouseout="c=false"
																		:for="'horse'+horses[tableIdx+2][idx].idejemplares+'pick3'"
																		:style="pickRace != tableIdx+2 ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
																	></label>
																</div>
															</div>
														</div>
														<template v-else>
															<div>
																<div class="round todo">
																	<input disabled type="checkbox" id="retirado" />
																	<label
																		for="retirado"
																		style="cursor: default;margin: 0 0 0 -3px;background:#eeeeee"
																		:style="pickRace != tableIdx ?'opacity:.5;background:#eee;':'opacity:.5;'"
																	>
																		<small style="position:relative;top:5px;z-index:0" class="d-flex">
																			<b class="mx-auto" style="color:black">R</b>
																		</small>
																	</label>
																</div>
															</div>
														</template>
													</template>
													<template v-else>
														<div
															style="opacity:0"
															v-if="selectedGameType == 'PICK 3' || selectedGameType == 'PICK 4'"
														>
															<div class="round todo" style="z-index:-1">
																<input disabled type="checkbox" id="retirado" />
																<label
																	for="retirado"
																	style="cursor: default;margin: 0 0 0 -3px;background:#eeeeee;z-index:-1"
																>
																	<small style="position:relative;top:5px;z-index:0" class="d-flex">
																		<b class="mx-auto" style="color:black">R</b>
																	</small>
																</label>
															</div>
														</div>
													</template>
												</div>
											</td>
											<td class="check" style="width:1px;padding: 0 12px;" v-if="selectedGameType == 'PICK 4'">
												<div class="d-flex">
													<template
														v-if="typeof horses[tableIdx+3] !== 'undefined' && idx <= horses[tableIdx+3].length-1 "
													>
														<div class="d-flex" v-if="horses[tableIdx+3][idx].estatus_ejemplar == 'ACTIVO'">
															<div>
																<div class="round" v-if="idx <= horses[tableIdx+3].length-1">
																	<input
																		v-model="jugada4"
																		type="checkbox"
																		:value="horses[tableIdx+3][idx].idejemplares"
																		:id="'horse'+horses[tableIdx+3][idx].idejemplares+'pick4'"
																		:disabled="(horses[tableIdx+3][idx].estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horses[tableIdx+3][idx].estatus_ejemplar == 'RETIRADO'"
																	/>
																	<label
																		@mouseover="changePickRace(tableIdx+3,true);c=true"
																		@mouseout="c=false"
																		:style="pickRace != tableIdx+3 ?'opacity:.5;background:#eee;margin: 0 0 0 -3px;':'margin: 0 0 0 -3px;'"
																		:for="'horse'+horses[tableIdx+3][idx].idejemplares+'pick4'"
																	></label>
																</div>
															</div>
														</div>
														<template v-else>
															<div>
																<div class="round todo">
																	<input disabled type="checkbox" id="retirado" />
																	<label
																		for="retirado"
																		style="cursor: default;background:#eeeeee;margin: 0 0 0 -3px;"
																		:style="pickRace != tableIdx ?'opacity:.5;background:#eee;':'opacity:.5;'"
																	>
																		<small style="position:relative;top:5px;z-index:0" class="d-flex">
																			<b class="mx-auto" style="color:black">R</b>
																		</small>
																	</label>
																</div>
															</div>
														</template>
													</template>
													<template v-else>
														<div style="opacity:0" v-if="selectedGameType == 'PICK 4'">
															<div class="round todo" style="z-index:-1">
																<input disabled type="checkbox" id="retirado" />
																<label
																	for="retirado"
																	style="cursor: default;background:#eeeeee;z-index:-1;margin: 0 0 0 -3px;"
																>
																	<small style="position:relative;top:5px;z-index:0" class="d-flex">
																		<b class="mx-auto" style="color:black">R</b>
																	</small>
																</label>
															</div>
														</div>
													</template>
												</div>
											</td>
										</tr>
										<tr style="background:transparent !important;">
											<td></td>
										</tr>
										<tr v-if="noEvents">
											<td colspan="5">
												<div class="d-flex">
													<b class="mx-auto">No hay datos dispobinles</b>
												</div>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
					</v-col>
				</v-row>
				<template v-else>
					<v-tabs
						v-if="selectedEvent.estatus=='CERRADA'"
						style="border-radius:5px"
						class="mb-2 elevation-0"
						:slider-color="colors.primary"
						:color="colors.primary"
						v-model="tab"
						grow
						background-color="white"
						height="36"
					>
						<v-tab v-for="item in items" :key="item.tab">{{ item.tab }}</v-tab>
					</v-tabs>
					<!-- <v-divider style="border-color:#dee2e6;"></v-divider> -->
					<v-tabs-items
						:touchless="selectedEvent.estatus!='CERRADA'"
						v-model="tab"
						style="background:transparent"
					>
						<v-tab-item v-for="item in items" :key="item.tab">
							<div style="background: transparent;" class="rounded">
								<v-simple-table
									:dark="mode"
									v-if="tab == 0"
									class="horse"
									fixed-header
									:height="size > 960 ? selectedEvent.estatus == 'CERRADA'?'calc(100vh - 222px)':'calc(100vh - 180px)':bSheet?'calc(100vh - 284px)':selectedEvent.estatus == 'CERRADA'?'calc(100vh - 208px)':'calc(100vh - 164px)'"
								>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="pt-0 text-left">
													<b>Gate</b>
												</th>
												<th class="pt-0 text-left">
													<b>Nombre</b>
												</th>
												<th class="pt-0 text-left" v-if="size > 960">
													<b>Jockey</b>
												</th>
												<th class="pt-0 text-left">
													<b>ML</b>
												</th>
												<th
													class="text-center"
													style="padding: 0 12px;"
													v-if="selectedGameType == 'G/P/S' ||selectedGameType == 'G/P' || selectedGameType == 'GANADOR' || selectedGameType == 'PLACE' || selectedGameType == 'SHOW' || selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
												>
													<div class style="position:relative;top:-1px">
														<div class="round todo">
															<input
																:disabled="selectedEvent.estatus=='CERRADA'"
																@click="selectAllJugada(1)"
																v-model="allJugadaSelected"
																type="checkbox"
																id="jugada"
															/>
															<label for="jugada" style="margin: 0 0 0 -3px;">
																<small v-if="!allJugadaSelected" style="position:relative;top:4.5px;z-index:99">TODO</small>
																<small v-else style="position:relative;top:4.5px;z-index:99;color:#fff">TODO</small>
															</label>
														</div>
													</div>
												</th>

												<th
													class="text-center"
													style="padding: 0 12px;"
													v-if="selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
												>
													<div style="position:relative;top:-1px">
														<div class="round todo">
															<input
																:disabled="selectedEvent.estatus=='CERRADA'"
																@click="selectAllJugada(2)"
																v-model="allJugada2Selected"
																type="checkbox"
																id="jugada2"
															/>
															<label for="jugada2" style="margin: 0 0 0 -3px;">
																<small
																	v-if="!allJugada2Selected"
																	style="position:relative;top:4.5px;z-index:99"
																>TODO</small>
																<small v-else style="position:relative;top:4.5px;z-index:99;color:#fff">TODO</small>
															</label>
														</div>
													</div>
												</th>

												<th
													class="text-center"
													style="padding: 0 12px;"
													v-if="selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
												>
													<div style="position:relative;top:-1px">
														<div class="round todo">
															<input
																:disabled="selectedEvent.estatus=='CERRADA'"
																@click="selectAllJugada(3)"
																v-model="allJugada3Selected"
																type="checkbox"
																id="jugada3"
															/>
															<label for="jugada3" style="margin: 0 0 0 -3px;">
																<small
																	v-if="!allJugada3Selected"
																	style="position:relative;top:4.5px;z-index:99"
																>TODO</small>
																<small v-else style="position:relative;top:4.5px;z-index:99;color:#fff">TODO</small>
															</label>
														</div>
													</div>
												</th>

												<th
													class="text-center"
													style="padding: 0 12px;"
													v-if="selectedGameType == 'SUPERFECTA'"
												>
													<div style="position:relative;top:-1px" v-if="selectedGameType == 'SUPERFECTA'">
														<div class="round todo">
															<input
																:disabled="selectedEvent.estatus=='CERRADA'"
																@click="selectAllJugada(4)"
																v-model="allJugada4Selected"
																type="checkbox"
																id="jugada4"
															/>
															<label for="jugada4" style="margin: 0 0 0 -3px;">
																<small
																	v-if="!allJugada4Selected"
																	style="position:relative;top:4.5px;z-index:99"
																>TODO</small>
																<small v-else style="position:relative;top:4.5px;z-index:99;color:#fff">TODO</small>
															</label>
														</div>
													</div>
												</th>
											</tr>
										</thead>

										<tbody v-if="loadingTable">
											<tr>
												<td
													:colspan="size > 960? 5:4"
													:style="size > 960?'height: calc(100vh - 318px)':'height: calc(100vh - 218px)'"
												>
													<div class="d-flex">
														<v-btn text depressed color="white" loading class="mx-auto">Cargando</v-btn>
													</div>
												</td>
											</tr>
										</tbody>
										<tbody v-else>
											<tr
												v-for="horse in horses"
												:key="horse.idejemplares"
												:style="(horse.estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horse.estatus_ejemplar == 'RETIRADO' ? 'opacity:.64':''"
											>
												<td style>
													<img
														style="border-radius:50%;opacity:.84"
														:src="'https://www.trackinfo.com/'+horse.numero"
														alt
														srcset
													/>
												</td>
												<td style="font-weight:500">{{ horse.nombre}}</td>
												<td v-if="size > 960">{{ horse.jinete}}</td>
												<td>
													<b>{{ horse.ml}}</b>
												</td>

												<td
													style="width:1px;padding: 0 12px;"
													v-if="selectedGameType == 'G/P/S' ||selectedGameType == 'G/P' || selectedGameType == 'GANADOR' || selectedGameType == 'PLACE' || selectedGameType == 'SHOW' || selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
													:colspan="selectedGameType == 'EXACTA' && horse.estatus_ejemplar != 'ACTIVO' ? 2   :selectedGameType == 'TRIFECTA' && horse.estatus_ejemplar != 'ACTIVO'? 3:selectedGameType == 'SUPERFECTA' && horse.estatus_ejemplar != 'ACTIVO'? 4:''"
												>
													<div class="d-flex" v-if="horse.estatus_ejemplar == 'ACTIVO'">
														<div class>
															<div class="round">
																<input
																	v-model="jugada"
																	type="checkbox"
																	:value="horse.idejemplares"
																	:id="'checkbox'+horse.idejemplares"
																	:disabled="(horse.estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horse.estatus_ejemplar == 'RETIRADO'"
																/>
																<label style="margin: 0 0 0 -3px;" :for="'checkbox'+horse.idejemplares"></label>
															</div>
														</div>
													</div>
													<div class="d-flex" v-else>
														<small class="mx-auto">
															<b
																class="white---text"
																v-if="selectedGameType == 'G/P/S' ||selectedGameType == 'G/P' || selectedGameType == 'GANADOR' || selectedGameType == 'PLACE' || selectedGameType == 'SHOW'"
															>R</b>
															<b style v-else>RETIRADO</b>
														</small>
														<div class="d-none">
															<div class="round todo">
																<input disabled type="checkbox" id="retirado" />
																<label for="retirado" style="background:#eeeeee">
																	<small style="position:relative;top:5px;z-index:0" class="d-flex">
																		<b class="mx-auto" style="color:red">R</b>
																	</small>
																</label>
															</div>
														</div>
													</div>
												</td>

												<template
													v-if="selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
												>
													<td style="width:1px;padding: 0 12px;" v-if="horse.estatus_ejemplar == 'ACTIVO'">
														<div class="d-flex">
															<div>
																<div class="round">
																	<input
																		v-model="jugada2"
																		type="checkbox"
																		:value="horse.idejemplares"
																		:id="'horse'+horse.idejemplares+'e'"
																		:disabled="(horse.estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horse.estatus_ejemplar == 'RETIRADO'"
																	/>
																	<label style="margin: 0 0 0 -3px;" :for="'horse'+horse.idejemplares+'e'"></label>
																</div>
															</div>
														</div>
													</td>
												</template>

												<template v-if="selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'">
													<td style="width:1px;padding: 0 12px;" v-if="horse.estatus_ejemplar == 'ACTIVO'">
														<div class="d-flex">
															<div>
																<div class="round">
																	<input
																		v-model="jugada3"
																		type="checkbox"
																		:value="horse.idejemplares"
																		:id="'horse'+horse.idejemplares+'a'"
																		:disabled="(horse.estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horse.estatus_ejemplar == 'RETIRADO'"
																	/>
																	<label style="margin: 0 0 0 -3px;" :for="'horse'+horse.idejemplares+'a'"></label>
																</div>
															</div>
														</div>
													</td>
												</template>

												<template v-if="selectedGameType == 'SUPERFECTA'">
													<td style="width:1px;padding: 0 12px;" v-if="horse.estatus_ejemplar == 'ACTIVO'">
														<div class="d-flex">
															<div>
																<div class="round">
																	<input
																		v-model="jugada4"
																		type="checkbox"
																		:value="horse.idejemplares"
																		:id="'horse'+horse.idejemplares+'b'"
																		:disabled="(horse.estatus_ejemplar == 'RETIRADO' && selectedEvent.estatus == 'CERRADA') || selectedEvent.estatus == 'CERRADA' || horse.estatus_ejemplar == 'RETIRADO'"
																	/>
																	<label style="margin: 0 0 0 -3px;" :for="'horse'+horse.idejemplares+'b'"></label>
																</div>
															</div>
														</div>
													</td>
												</template>
											</tr>
											<tr v-if="!noEvents" style="background: transparent !important;">
												<td></td>
											</tr>
											<tr v-if="noEvents">
												<td colspan="5">
													<div class="d-flex">
														<b class="mx-auto">No hay datos dispobinles</b>
													</div>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
								<template v-else>
									<v-simple-table :dark="mode" class="horse" fixed-header>
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">EJEMPLAR</th>
													<th class="text-left">GANADOR</th>
													<th class="text-left">PLACE</th>
													<th class="text-left">SHOW</th>
												</tr>
											</thead>
											<tbody v-if="loadingTable">
												<tr>
													<td
														:colspan="size > 960? 5:4"
														:style="size > 960?'height: calc(100vh - 769px)':'height: calc(100vh - 569px)'"
													>
														<div class="d-flex">
															<v-btn text depressed color="white" loading class="mx-auto">Cargando</v-btn>
														</div>
													</td>
												</tr>
											</tbody>
											<tbody v-else>
												<template v-for="(horse,idx) in resultados">
													<tr v-if="idx <= 2" :key="idx">
														<td>
															<img
																:src="'https://www.trackinfo.com/images/bg-place-t'+horse.seleccion+'.gif'"
																alt
																srcset
															/>
														</td>
														<td>{{ horse.ganador == ''?'':'$'+horse.ganador }}</td>
														<td>{{ horse.place == '' ?'':'$'+horse.place }}</td>
														<td>{{ horse.show == ''?'':'$'+horse.show }}</td>
													</tr>
												</template>
											</tbody>
										</template>
									</v-simple-table>
									<v-divider class="my-1"></v-divider>
									<v-simple-table :dark="mode" class="horse" fixed-header>
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">JUGADA</th>
													<th class="text-left">EJEMPLARES</th>
													<th class="text-left">PAGO</th>
												</tr>
											</thead>
											<tbody v-if="loadingTable">
												<tr>
													<td
														:colspan="size > 960? 5:4"
														:style="size > 960?'height: calc(100vh - 618px)':'height: calc(100vh - 468px)'"
													>
														<div class="d-flex">
															<v-btn text depressed color="white" loading class="mx-auto">Cargando</v-btn>
														</div>
													</td>
												</tr>
											</tbody>
											<tbody v-else>
												<template v-for="(horse,idx) in resultados">
													<tr v-if="idx>2" :key="idx">
														<td>
															<b>{{horse.factor}} | {{horse.jugada}}</b>
														</td>
														<td>{{horse.seleccion}}</td>
														<td>{{horse.pago }}</td>
													</tr>
												</template>
											</tbody>
										</template>
									</v-simple-table>
								</template>
							</div>
						</v-tab-item>
					</v-tabs-items>
				</template>
			</v-col>
		</v-row>

		<v-bottom-sheet v-model="bSheet" hide-overlay persistent no-click-animation>
			<v-sheet class="text-center" height="170px">
				<v-card flat>
					<v-card-title class="body-2 py-1">
						<b class>CARRERA {{selectedEvent.idcarrera}}</b>
						<b class="mx-auto">{{selectedGameType}}</b>
						<b>{{selectedAmout}} UND</b>
						<v-btn icon class="ml-1" @click="cleanJugada()">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<div>
							<div class="d-flex">
								<div>
									<span></span>
									<span v-for="(i,index) in jugada" :key="i">
										<span v-if="index !== 0">-</span>
										<b style="color:#000">{{i}}</b>
									</span>
								</div>
								<div>
									<span class="mx-1">
										<b style="color:red">/</b>
									</span>
									<span v-for="(i,index) in jugada2" :key="i">
										<span v-if="index !== 0">-</span>
										<b style="color:#000">{{i}}</b>
									</span>
								</div>
								<div>
									<span class="mx-1">
										<b style="color:red">/</b>
									</span>
									<span v-for="(i,index) in jugada3" :key="i">
										<span v-if="index !== 0">-</span>
										<b style="color:#000">{{i}}</b>
									</span>
								</div>
								<div>
									<span class="mx-1">
										<b style="color:red">/</b>
									</span>
									<span v-for="(i,index) in jugada4" :key="i">
										<span v-if="index !== 0">-</span>
										<b style="color:#000">{{i}}</b>
									</span>
								</div>
							</div>
						</div>
					</v-card-text>
					<v-card-actions class>
						<!-- <v-btn color="error" text @click="dialogTicket = false">Cancelar</v-btn> -->

						<v-btn
							class="mt-2"
							:loading="loading"
							color="#4caf50"
							block
							depressed
							:dark="GPS.monto == 0?false:true || !loading?true:false"
							@click="dialogTicket = true"
							:disabled="GPS.monto == 0 || loading"
						>{{GPS.monto }} UND - JUGAR</v-btn>
					</v-card-actions>
				</v-card>
			</v-sheet>
		</v-bottom-sheet>

		<v-dialog v-model="dialogTicket" max-width="400">
			<v-card>
				<v-card-title class="title py-3">{{selectedEvent.nombre}}</v-card-title>
				<v-card-text class="py-0" style="background:;border-radius:4px">
					<div class="d-flex" v-if="!loadingSaldo">
						<div class="text-left">
							<h4 class="font-weight-medium ma-0">CARTERA</h4>
							{{saldos[0].simbolo}} ({{saldos[0].moneda}}) {{ saldos[0].pais == 'ECUADOR'? '- ECUADOR': saldos[0].pais == 'EEUU'? '- EEUU':''}}
						</div>
						<div class="ml-auto">
							<h4 class="font-weight-medium ma-0">SALDO</h4>
							<b>{{currentBalance}} UND</b>
						</div>
						<div class="ml-auto">
							<h4 class="font-weight-medium ma-0">BONO</h4>
							<b>{{currentBono}} UND</b>
						</div>
					</div>

					<v-divider class="my-3"></v-divider>

					<h4 class="font-weight-medium mb-1">TICKET</h4>
					<v-divider></v-divider>
					<div>
						<div class="d-flex px-1">
							<b class="font-weight-medium">{{selectedGameType}}</b>
							<b class="font-weight-medium mx-auto">CARRERA {{selectedEvent.idcarrera}}</b>
							<b class="font-weight-medium">{{selectedAmout}} UND</b>
						</div>
						<v-divider></v-divider>
						<div>
							<!-- <b class="font-weight-regular">SELECCIÓN</b>
							<v-divider></v-divider>-->

							<div class="py-1 rounded">
								<div class="ticket-content">
									<div class="px-2" v-if="jugada.length">
										<span>
											<b class="font-weight-regular">1°</b>
											<span class="ml-1">|</span>
										</span>
										<span v-for="(i,index) in jugada" :key="i">
											<span v-if="index !== 0">-</span>
											{{i}}
										</span>
									</div>
									<v-divider v-if="jugada2.length && jugada.length"></v-divider>
									<div class="px-2" v-if="jugada2.length">
										<span>
											<b class="font-weight-regular">2°</b>
											<span class="ml-1">|</span>
										</span>
										<span v-for="(i,index) in jugada2" :key="i">
											<span v-if="index !== 0">-</span>
											{{i}}
										</span>
									</div>
									<v-divider v-if="jugada3.length && jugada2.length"></v-divider>
									<div class="px-2" v-if="jugada3.length">
										<span>
											<b class="font-weight-regular">3°</b>
											<span class="ml-1">|</span>
										</span>
										<span v-for="(i,index) in jugada3" :key="i">
											<span v-if="index !== 0">-</span>
											{{i}}
										</span>
									</div>
									<v-divider v-if="jugada4.length && jugada3.length"></v-divider>
									<div class="px-2" v-if="jugada4.length">
										<span>
											<b class="font-weight-regular">4°</b>
											<span class="ml-1">|</span>
										</span>
										<span v-for="(i,index) in jugada4" :key="i">
											<span v-if="index !== 0">-</span>
											{{i}}
										</span>
									</div>
								</div>
							</div>
						</div>
						<v-divider></v-divider>
						<div class="d-flex px-1">
							<b class="font-weight-medium">TOTAL</b>
							<b class="font-weight-medium ml-auto">{{GPS.monto }} UND</b>
						</div>
						<v-divider class="mb-1"></v-divider>
					</div>
					<v-checkbox small :color="colors.primary" hide-details v-model="bono" label="Jugar con bono"></v-checkbox>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn text depressed :disabled="loading" @click="dialogTicket = false">Cancelar</v-btn>
					<v-btn :loading="loading" color="green" depressed dark @click="sendJugada">Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogOtro" max-width="290">
			<v-card>
				<v-card-title class="title">Otro monto</v-card-title>

				<v-card-text>
					<v-text-field
						v-if="dialogOtro"
						autofocus
						class="mt-4 mb-3"
						label="0"
						single-line
						dense
						solo
						flat
						background-color="blue-grey lighten-5"
						hide-details
						v-model="dMonto"
					></v-text-field>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="red" text @click="dialogOtro = false">Cancelar</v-btn>

					<v-btn color="green darken-1" text @click="otroMonto">Aceptar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogRules">
			<v-card>
				<v-icon
					style="position:absolute;z-index:99;right:6px;top:6px"
					size="40"
					class
					dark
					@click="dialogRules = false"
				>mdi-close</v-icon>
				<v-img src="../assets/img/rules.jpg"></v-img>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogProgramas" max-width="380px" scrollable>
			<v-card>
				<v-card-title>Programas</v-card-title>
				<v-card-text>
					<v-list>
						<v-list-item v-for="(item,idx) in programas" :key="idx" :href="item.url" target="_blank">
							<v-list-item-content>
								<v-list-item-title>{{item.nombre.split('.pdf')[0]}}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn icon>
									<v-icon color="grey lighten-1">mdi-link</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn :color="colors.error" text @click="dialogProgramas = false">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog max-width="650px" v-model="dialogC" class="py-0 mt-0">
			<v-card>
				<v-card-title class="body-1">CANALES</v-card-title>
				<v-card-text>
					<div v-if="size<960 && canal != ''">
						<v-alert dark :color="colors.error" v-if="currentBalance.toLocaleString() < '1.00'">
							<v-icon>mdi-alert</v-icon>Por favor recargue su saldo para ver los canales en vivo
						</v-alert>
						<v-btn
							v-else
							block
							style="border-radius:5px"
							:loading="loading"
							:color="colors.error"
							dark
							depressed
							class="ml-1 mb-3 px-2"
							@click="openCanales(canal)"
						>
							<span :class="marca == 'Betgana Races'?'black--text':''">
								<v-icon>mdi-play-circle-outline</v-icon>
								{{canal != ""?this.selectedEvent.nombre+', En vivo':'CANALES EN VIVO'}}
							</span>
						</v-btn>
					</div>
					<v-row v-if="currentBalance.toLocaleString() > '1.00'">
						<v-col class="my-0 py-0" cols="12">
							<h4>Todos</h4>
						</v-col>
						<v-col v-for="(i,idx) in canales" :key="i.id">
							<div class style="cursor:pointer" @click="openCanales(i.web_stream)">
								<div class="channel-item">
									<div class="d-flex">
										<v-img class="mt-3 mx-auto" src="../assets/img/logo.svg" max-width="50px"></v-img>
									</div>
									<div class="d-flex">
										<b class="mx-auto">{{idx+1}}</b>
									</div>
								</div>
								<div class="d-flex">
									<small class="mx-auto ml-2 pt-1">
										<b>{{i.name}}</b>
									</small>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn :color="colors.error" text @click="dialogC = false">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogChannel" style="height: 100% !important'">
			<v-card ref="canal" style="margin: 0 0 -8px;overflow: hidden;height:100%">
				<v-icon
					style="position:absolute;z-index:999;right:6px;top:6px"
					size="30"
					class
					dark
					@click="dialogChannel = false;chan=null"
				>mdi-close</v-icon>
				<iframe
					v-if="dialogChannel"
					:src="chan"
					frameborder="0"
					:style="size < 560 ? 'width: inherit; height: 28vh':size > 700 && size < 960?'width: inherit; height: calc(100vh - 4rem)':'width: inherit; height: calc(100vh - 7rem) '"
				></iframe>
				<!-- :style="size > 600? size<960? size<768?'width:100%; height: calc(100vh - 42px);':'width:100%;height: calc(100vh - 42px)':'width:100%; height: calc(100vh - 92px);':size < 600? 'width:100%;height: 28vh':'width:100%;height: calc(100vh - 42px)'" -->
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogEvents" scrollable>
			<v-card>
				<v-card-title class="body-1">EVENTOS</v-card-title>
				<v-card-text class="px-2">
					<v-list two-line flat color="transparent" v-if="selectedEventType == 'HIPODROMOS'">
						<v-list-item
							v-for="item in hipodromos"
							:key="item.nombre"
							class="mb-1 py-0"
							style="height: 48px; min-height: 48px"
							@click="changeEventMobile(item)"
						>
							<v-list-item-avatar size="32">
								<v-btn
									v-if="item.canal !='' && item.estatus != 'CERRADA'"
									@click="openCanalMobile(item.canal)"
									style="z-index:99;border: 1px solid #eb262c;"
									icon
									small
								>
									<v-icon color="#eb262c">mdi-play</v-icon>
								</v-btn>
								<v-img v-else src="../assets/img/hipo.svg"></v-img>
							</v-list-item-avatar>
							<v-list-item-content class="py-0">
								<v-list-item-title>
									<b style="font-size:14px">{{item.nombre}}</b>
								</v-list-item-title>
								<v-list-item-subtitle>
									<small class="mr-2">
										<b>CARRERA {{item.idcarrera}}</b>
									</small>

									<v-chip
										class="px-2"
										x-small
										dark
										label
										:color="colors.primary"
										v-if="item.estatus == 'ABIERTA'"
									>
										<small>
											<b :class="marca == 'Betgana Races'?'black--text':''">{{item.estatus}}</b>
										</small>
									</v-chip>
									<v-chip
										class="px-2"
										x-small
										label
										:color="colors.warning"
										v-if="item.estatus == 'CORRIENDO'"
									>
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<v-chip
										class="px-2"
										x-small
										dark
										label
										:color="colors.error"
										v-if="item.estatus == 'CERRADA'"
									>
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<small
										v-if="item.mtp !== '' && item.estatus != 'CORRIENDO' && item.estatus != 'CERRADA'"
										class="ml-1 font-weight-medium"
										:class="item.mtp == 0?'animate-mtp p-ab white--text':''"
									>[ {{item.mtp}} MTP ]</small>
								</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>
								<v-chip class small label :color="colors.primary" :dark="marca != 'Betgana Races'">
									<b>{{item.nivel}}</b>
								</v-chip>
							</v-list-item-action>
						</v-list-item>
						<v-divider></v-divider>

						<v-list-item
							v-for="item in galgos"
							:key="item.nombre"
							class="mb-1 py-0"
							style="height: 48px; min-height: 48px"
							@click="changeEvent(item);dialogEvents=false;"
						>
							<v-list-item-avatar size="32">
								<v-btn
									@mouseenter="hover = true"
									@mouseleave="hover = false"
									v-if="item.canal !='' && item.estatus != 'CERRADA'"
									@click="openCanal(item.canal)"
									style="z-index:99;border: 1px solid #eb262c;"
									icon
									small
								>
									<v-icon color="#eb262c">mdi-play</v-icon>
								</v-btn>
								<v-img v-else src="../assets/img/galgos.svg"></v-img>
							</v-list-item-avatar>
							<v-list-item-content class="py-0">
								<v-list-item-title>
									<b style="font-size:14px">{{item.nombre}}</b>
								</v-list-item-title>
								<v-list-item-subtitle>
									<small class="mr-1">
										<b>CARRERA {{item.idcarrera}}</b>
									</small>
									<v-chip class="px-2" x-small dark label color="#00afef" v-if="item.estatus == 'ABIERTA'">
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<v-chip class="px-2" x-small label color="#ffc107" v-if="item.estatus == 'CORRIENDO'">
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<v-chip class="px-2" x-small dark label color="#eb262c" v-if="item.estatus == 'CERRADA'">
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<small
										v-if="item.mtp !== '' && item.estatus != 'CORRIENDO' && item.estatus != 'CERRADA'"
										class="ml-1 font-weight-medium"
										:class="item.mtp == 0?'animate-mtp p-ab white--text':''"
									>[ {{item.mtp}} MTP ]</small>
								</v-list-item-subtitle>
							</v-list-item-content>

							<!-- <v-list-item-action>
									<v-chip class small label color="#1459b4" dark>
										<b>{{item.nivel}}</b>
									</v-chip>
							</v-list-item-action>-->
						</v-list-item>
						<v-divider></v-divider>

						<v-list-item
							v-for="item in carretas"
							:key="item.title"
							class="mb-1 py-0"
							style="height: 48px; min-height: 48px"
							@click="changeEvent(item);dialogEvents=false;"
						>
							<v-list-item-avatar size="32">
								<v-btn
									@mouseenter="hover = true"
									@mouseleave="hover = false"
									v-if="item.canal !='' && item.estatus != 'CERRADA'"
									@click="openCanal(item.canal)"
									style="z-index:99;border: 1px solid #eb262c;"
									icon
									small
								>
									<v-icon color="#eb262c">mdi-play</v-icon>
								</v-btn>
								<v-img v-else src="../assets/img/carretas.svg"></v-img>
							</v-list-item-avatar>
							<v-list-item-content class="py-0">
								<v-list-item-title>
									<b style="font-size:14px">{{item.nombre}}</b>
								</v-list-item-title>
								<v-list-item-subtitle>
									<small class="mr-1">
										<b>CARRERA {{item.idcarrera}}</b>
									</small>
									<v-chip class="px-2" x-small dark label color="#00afef" v-if="item.estatus == 'ABIERTA'">
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<v-chip class="px-2" x-small label color="#ffc107" v-if="item.estatus == 'CORRIENDO'">
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<v-chip class="px-2" x-small dark label color="#eb262c" v-if="item.estatus == 'CERRADA'">
										<small>
											<b>{{item.estatus}}</b>
										</small>
									</v-chip>
									<small
										v-if="item.mtp !== '' && item.estatus != 'CORRIENDO' && item.estatus != 'CERRADA'"
										class="ml-1 font-weight-medium"
										:class="item.mtp == 0?'animate-mtp p-ab white--text':''"
									>[ {{item.mtp}} MTP ]</small>
								</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>
								<v-chip class small label color="#1459b4" dark>
									<b>{{item.nivel}}</b>
								</v-chip>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red" text @click="dialogEvents = false">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snack" color="white">
			<v-list-item-avatar color="green" size="32" v-if="mensaje == 'La Apuesta se realizo con exito'">
				<v-icon dark>mdi-check</v-icon>
			</v-list-item-avatar>

			<v-list-item-avatar color="red" size="32" v-else>
				<v-icon dark>mdi-cancel</v-icon>
			</v-list-item-avatar>

			<b style="color:#000">{{ mensaje}}</b>
			<v-btn color="blue" text @click="snack = false">Cerrar</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { mapActions } from "vuex";

import date from "../date.js";
import Events from "../components/bet/Events";
import EventDetails from "../components/bet/EventDetails";
export default {
	components: {
		Events,
		EventDetails,
	},
	props: {
		source: String,
	},
	data: () => ({
		dialogTicket: false,
		dialogOtro: false,
		dialogRules: false,
		dialogCanales: false,
		bSheet: false,
		dMonto: "",
		jugada: [],
		jugada2: [],
		jugada3: [],
		jugada4: [],
		pick: null,
		totalcomb: [],
		selectedAmout: "1",
		amount: ["1", "2", "5", "10", "20", "50", "100", "200", "500", "OTRO"],
		drawer: null,
		dialogLogin: false,
		eventType: ["HIPODROMOS", "GALGOS", "CARRETAS"],
		gameType: ["GANADOR", "PLACE", "SHOW"],
		selectedGameType: "GANADOR",
		selectedEventType: "HIPODROMOS",
		events: [],
		races: [],
		selectedRace: "",
		selectedStatus: "",
		horses: [],
		results: [],
		selectdHorses: [],
		allJugadaSelected: false,
		allJugada2Selected: false,
		allJugada3Selected: false,
		allJugada4Selected: false,
		snack: false,
		mensaje: "",
		loading: false,
		balance: 0,
		noEvents: false,
		channels: [],
		dialogChannel: false,
		chan: "",
		idx: 0,
		loadingTable: true,
		tableIdx: 0,
		pickRace: 0,
		c: false,
		ctime: null,
		interval: {},
		items: [
			{ tab: "Carrera", content: "Race" },
			{ tab: "Resultados", content: "Result" },
		],
		tab: 0,
		primer: false,
		canal: null,
		programas: [],
		oc: false,
		bono: false,
		cash: false,
		confim: true,
	}),
	watch: {
		dialogProgramas(v) {
			if (v) {
				this.getProgramas();
			}
		},
		dialogC(v) {
			if (!v) {
				this.$router.push("bet");
			}
		},
		dialogChannel(v) {
			if (!v) {
				// this.chan = null;
				this.oc = false;
				clearTimeout(this.ctime);
			}
		},
		jugada(v) {
			if (
				v.length == 0 &&
				this.jugada2 == 0 &&
				this.jugada3 == 0 &&
				this.jugada4 == 0
			) {
				this.bSheet = false;
			} else if (this.size < 960) {
				this.bSheet = true;
			}
		},
		jugada2(v) {
			if (
				v.length == 0 &&
				this.jugada == 0 &&
				this.jugada3 == 0 &&
				this.jugada4 == 0
			) {
				this.bSheet = false;
			} else if (this.size < 960) {
				this.bSheet = true;
			}
		},
		jugada3(v) {
			if (
				v.length == 0 &&
				this.jugada == 0 &&
				this.jugada2 == 0 &&
				this.jugada4 == 0
			) {
				this.bSheet = false;
			} else if (this.size < 960) {
				this.bSheet = true;
			}
		},
		jugada4(v) {
			if (
				v.length == 0 &&
				this.jugada == 0 &&
				this.jugada3 == 0 &&
				this.jugada2 == 0
			) {
				this.bSheet = false;
			} else if (this.size < 960) {
				this.bSheet = true;
			}
		},
	},
	updated() {
		if (document.getElementById("fc_frame")) {
			document.getElementById("fc_frame").style.display = "none";
		}
	},
	mounted() {
		this.getSaldo();

		if (document.getElementById("fc_frame")) {
			document.getElementById("fc_frame").style.display = "none";
		}
		this.getPremios();
		axios({
			method: "post",
			url: `details`,
		}).then((response) => {
			this.$store.state.nombre = response.data.success.name;
		});
		axios({
			method: "get",
			url: `canales`,
		}).then((response) => {
			this.channels = response.data.data;
		});
		this.getEvents(null, null, true);
		this.interval = setInterval(() => {
			this.getEvents(false, this.pick);
			this.getPremios();
		}, 90000);
	},
	beforeDestroy() {
		document.getElementById("fc_frame").style.display = "block";
		clearInterval(this.interval);
	},
	methods: {
		...mapActions(["getSaldo", "changeLoadingSaldo"]),
		changeEventMobile(item) {
			setTimeout(() => {
				if (this.oc == false) {
					this.changeEvent(item, true);
					this.dialogEvents = false;
					this.oc = false;
				}
			}, 1);
		},
		openCanalMobile(canal) {
			this.oc = true;
			this.openCanales(canal);
		},
		oCanales() {
			if (this.currentBalance.toLocaleString() != "0.00") {
				if (this.canal != "") {
					this.openCanales(this.canal);
				} else {
					this.dialogC = true;
				}
			}
		},
		getProgramas() {
			axios({
				method: "get",
				url: `/programas`,
			}).then((response) => {
				this.programas = response.data.Gazeta;
			});
		},
		getPick() {
			axios({
				method: "get",
				url: `/cargarcarrerapick/${
					this.selectedEvent.idcarrera
				}/${date.today()}/${this.selectedEvent.idhipodromo}/${this.pick}`,
			}).then((response) => {
				this.tableIdx = this.selectedEvent.idcarrera;
				this.horses = response.data;
				for (const key in response.data) {
					if (key == this.selectedEvent.idcarrera) {
						this.pickRace = key;
					}
				}
				this.loadingTable = false;
			});
		},
		getPremios() {
			axios({
				method: "get",
				url: `/new_premios`,
			}).then((response) => {
				console.log(response);
				this.premios = response.data.premios[0];
			});
		},
		getEvents(v, p) {
			axios({
				method: "get",
				url: `mostrareventos_estatus/${date.today()}`,
			}).then((response) => {
				this.events = response.data;

				this.events.sort((a, b) => {
					return a.mtp - b.mtp;
				});
				
				console.log(this.events);

				this.$store.state.events = this.events;
				if (this.events.length == 0) {
					this.noEvents = true;
					this.loadingTable = false;
				} else {
					this.noEvents = false;
				}
				if (this.events.length) {
					if (v == undefined) {
						console.log(v);
						this.$store.state.selectedEvent = {
							nombre: this.hipodromos[0].nombre,
							idcarrera: this.hipodromos[0].idcarrera,
							idhipodromo: this.hipodromos[0].idhipodromo,
							estatus: this.hipodromos[0].estatus,
							tipo: this.hipodromos[0].tipo,
							nivel: this.hipodromos[0].nivel,
						};
					}
					this.changeEvent(this.selectedEvent, v, p);
				}
			});
		},
		changeEvent(value, v, p) {
			if (value.estatus == "CERRADA") {
				this.tab = 1;
			} else {
				this.tab = 0;
			}
			if (v) {
				this.loadingTable = true;
			}
			axios({
				method: "get",
				url: `cargarcarreras/${date.today()}/${value.idhipodromo}`,
			}).then((response) => {
				this.races = response.data;
				this.selectedEvent.nombre = value.nombre;
				this.selectedEvent.idcarrera = value.idcarrera;
				this.selectedEvent.idhipodromo = value.idhipodromo;
				this.selectedEvent.estatus = value.estatus;
				this.selectedEvent.nivel = value.nivel;
				this.selectedEvent.tipo = value.tipo;

				this.changeRace(this.selectedEvent, v, p);
			});
			if (v) {
				this.jugada = [];
				this.jugada2 = [];
				this.jugada3 = [];
				this.jugada4 = [];
				this.allJugadaSelected = false;
				this.allJugada2Selected = false;
				this.allJugada3Selected = false;
				this.allJugada4Selected = false;
			}
		},
		changeRace(value, f, p) {
			if (f) {
				this.changeGameType("GANADOR");
				this.loadingTable = true;
			}
			this.selectedEvent.idcarrera = value.idcarrera;
			this.selectedEvent.estatus = value.estatus;
			if (!p && this.pick == null) {
				axios({
					method: "get",
					url: `idcarrerachange_resultado/${value.idcarrera}/${date.today()}/${
						value.idhipodromo
					}`,
				}).then((response) => {
					this.canal = response.data.canal;
					this.horses = response.data.ejemplares;
					this.results = response.data.resultados;

					this.gameType = ["GANADOR", "PLACE", "SHOW"];

					response.data.jugadas.map((v) => {
						this.gameType.push(v.toUpperCase());
					});

					this.gameType.push("G/P");
					this.gameType.push("G/P/S");
					this.loadingTable = false;
				});
			} else {
				this.getPick();
			}
		},
		changeGameType(value) {
			this.cleanJugada(1);
			this.pick = null;
			if (value == "PICK 2") {
				this.pick = 2;
				this.getPick();
			} else if (value == "PICK 3") {
				this.pick = 3;
				this.getPick();
			} else if (value == "PICK 4") {
				this.pick = 4;
				this.getPick();
			} else {
				this.pick = null;
				this.cleanJugada(1);
			}
			this.selectedGameType = value;
		},
		changeAmount(value) {
			if (value == "OTRO") {
				this.dialogOtro = true;
			} else {
				this.selectedAmout = value;
			}
		},
		otroMonto() {
			this.dialogOtro = false;
			this.selectedAmout = this.dMonto;
		},
		sendJugada() {
			this.loading = true;
			axios({
				method: "post",
				url: `new_guardarticket`,
				data: {
					idmoneda: this.saldos[0].idmoneda,
					bono: this.bono ? 1 : 0,
					idhipodromo: this.selectedEvent.idhipodromo,
					idcarrera: this.selectedEvent.idcarrera,
					idjugadas: this.typeGame,
					texto_jugada: this.GPS.comb,
					cantidad: this.selectedAmout,
					monto_jugada: this.GPS.monto,
				},
			}).then((response) => {
				if (response.data.mensaje == "La Apuesta se realizo con exito") {
					this.getSaldo();
					this.getEvents(false);
					this.bono = false;
				}
				this.cleanJugada();
				this.mensaje = response.data.mensaje;
				this.snack = true;
			});
		},
		cleanJugada(value) {
			this.getEvents(false);
			this.bSheet = false;
			this.loading = false;
			this.dialogTicket = false;
			this.jugada = [];
			this.jugada2 = [];
			this.jugada3 = [];
			this.jugada4 = [];
			if (value == "undefined") {
				this.selectedGameType = "GANADOR";
			}
			this.selectedAmout = "1";
			this.allJugadaSelected = false;
			this.allJugada2Selected = false;
			this.allJugada3Selected = false;
			this.allJugada4Selected = false;
		},
		selectAllJugada(value) {
			if (value == 1) {
				this.jugada = [];
			}
			if (value == 2) {
				this.jugada2 = [];
			}

			if (value == 3) {
				this.jugada3 = [];
			}

			if (value == 4) {
				this.jugada4 = [];
			}

			if (value == 1 && !this.allJugadaSelected) {
				if (this.pick == 2 || this.pick == 3 || this.pick == 4) {
					for (let horse in this.horses[this.tableIdx]) {
						if (
							this.horses[this.tableIdx][horse].estatus_ejemplar == "ACTIVO"
						) {
							this.jugada.push(this.horses[this.tableIdx][horse].idejemplares);
						}
					}
				} else {
					for (let horse in this.horses) {
						if (this.horses[horse].estatus_ejemplar == "ACTIVO") {
							this.jugada.push(this.horses[horse].idejemplares);
						}
					}
				}
			}

			if (value == 2 && !this.allJugada2Selected) {
				if (this.pick == 2 || this.pick == 3 || this.pick == 4) {
					for (let horse in this.horses[this.tableIdx + 1]) {
						if (
							this.horses[this.tableIdx + 1][horse].estatus_ejemplar == "ACTIVO"
						) {
							this.jugada2.push(
								this.horses[this.tableIdx + 1][horse].idejemplares
							);
						}
					}
				} else {
					for (let horse in this.horses) {
						if (this.horses[horse].estatus_ejemplar == "ACTIVO") {
							this.jugada2.push(this.horses[horse].idejemplares);
						}
					}
				}
			}

			if (value == 3 && !this.allJugada3Selected) {
				if (this.pick == 3 || this.pick == 4) {
					for (let horse in this.horses[this.tableIdx + 2]) {
						if (
							this.horses[this.tableIdx + 2][horse].estatus_ejemplar == "ACTIVO"
						) {
							this.jugada3.push(
								this.horses[this.tableIdx + 2][horse].idejemplares
							);
						}
					}
				} else {
					for (let horse in this.horses) {
						if (this.horses[horse].estatus_ejemplar == "ACTIVO") {
							this.jugada3.push(this.horses[horse].idejemplares);
						}
					}
				}
			}

			if (value == 4 && !this.allJugada4Selected) {
				if (this.pick == 4) {
					for (let horse in this.horses[this.tableIdx + 3]) {
						if (
							this.horses[this.tableIdx + 3][horse].estatus_ejemplar == "ACTIVO"
						) {
							this.jugada4.push(
								this.horses[this.tableIdx + 3][horse].idejemplares
							);
						}
					}
				} else {
					for (let horse in this.horses) {
						if (this.horses[horse].estatus_ejemplar == "ACTIVO") {
							this.jugada4.push(this.horses[horse].idejemplares);
						}
					}
				}
			}
		},
		changePickRace(tableIdx, v) {
			if (v) {
				setTimeout(() => {
					if (this.c) {
						this.pickRace = tableIdx;
					}
				}, 300);
			} else {
				this.pickRace = tableIdx;
			}
		},
		logout() {
			axios({
				method: "post",
				url: `/logout`,
			}).then((response) => {
				if (response.data.res) {
					this.$store.state.token = null;
					this.$router.replace("/");
					localStorage.removeItem("token");
					sessionStorage.removeItem("token");
				}
			});
		},
		openCanales(v) {
			this.chan = v;
			this.dialogChannel = true;
			this.ctime = setTimeout(() => {
				this.chan = null;
				this.dialogChannel = false;
			}, 300000);
		},
	},
	computed: {
		...mapState({
			saldos: "saldos",
			loadingSaldo: "loadingSaldo",
			currentBalance: "currentBalance",
			currentBono: "currentBono",
		}),
		premios: {
			get() {
				return this.$store.state.premios;
			},
			set(value) {
				this.$store.state.premios = value;
			},
		},
		selectedEvent: {
			get() {
				return this.$store.state.selectedEvent;
			},
			set(value) {
				this.$store.state.selectedEvent = value;
			},
		},
		dialogEvents: {
			get() {
				return this.$store.state.dialogEvents;
			},
			set(value) {
				this.$store.state.dialogEvents = value;
			},
		},
		dialogProgramas: {
			get() {
				return this.$store.state.dialogProgramas;
			},
			set(value) {
				this.$store.state.dialogProgramas = value;
			},
		},
		marca() {
			return this.$store.state.marca;
		},
		mode() {
			return this.$store.state.mode;
		},
		colors() {
			return this.$store.state.colors;
		},
		size() {
			return this.$store.state.windowSize.x;
		},
		resultados() {
			let re = [],
				idx = 0;
			this.results.map((value) => {
				idx = value.seleccion;
				if (value.jugada == "Ganador") {
					re.push({
						seleccion: value.seleccion,
						ganador: value.dividendo,
						factor: value.factor,
						place: "",
						show: "",
					});
				}
				if (value.jugada == "Place") {
					if (re[0].seleccion == idx) {
						re[0].place = value.dividendo;
					} else {
						re.push({
							seleccion: value.seleccion,
							ganador: "",
							factor: value.factor,
							place: value.dividendo,
							show: "",
						});
					}
				}
				if (value.jugada == "Show") {
					if (re[0].seleccion == idx) {
						re[0].show = value.dividendo;
					} else if (re.indexOf(1)) {
						if (re[1].seleccion == idx) {
							re[1].show = value.dividendo;
						} else {
							re.push({
								seleccion: value.seleccion,
								ganador: "",
								factor: value.factor,
								place: "",
								show: value.dividendo,
							});
						}
					}
				}
				if (value.jugada == "Exacta") {
					re.push({
						seleccion: value.seleccion,
						factor: "$" + value.factor,
						pago: "$" + value.dividendo,
						jugada: value.jugada.toUpperCase(),
					});
				}
				if (value.jugada == "Trifecta") {
					re.push({
						seleccion: value.seleccion,
						factor: "$" + value.factor,
						pago: "$" + value.dividendo,
						jugada: value.jugada.toUpperCase(),
					});
				}
				if (value.jugada == "Superfecta") {
					re.push({
						seleccion: value.seleccion,
						factor: "$" + value.factor,
						pago: "$" + value.dividendo,
						jugada: value.jugada.toUpperCase(),
					});
				}
				if (value.jugada == "Pick 2") {
					re.push({
						seleccion: value.seleccion,
						factor: "$" + value.factor,
						pago: "$" + value.dividendo,
						jugada: value.jugada.toUpperCase(),
					});
				}
				if (value.jugada == "Pick 3") {
					re.push({
						seleccion: value.seleccion,
						factor: "$" + value.factor,
						pago: "$" + value.dividendo,
						jugada: value.jugada.toUpperCase(),
					});
				}
				if (value.jugada == "Pick 4") {
					re.push({
						seleccion: value.seleccion,
						factor: "$" + value.factor,
						pago: "$" + value.dividendo,
						jugada: value.jugada.toUpperCase(),
					});
				}
			});
			return re;
		},
		dialogC: {
			get() {
				return this.$store.state.dialogChannel;
			},
			set(v) {
				this.$store.state.dialogChannel = v;
			},
		},
		horsesTable() {
			let value = this.horses;

			if (this.pick == 2) {
				if (value[this.tableIdx] >= value[this.tableIdx + 1]) {
					value = this.horses[this.tableIdx];
				} else {
					value = this.horses[this.tableIdx + 1];
				}
			}
			if (this.pick == 3) {
				if (value[this.tableIdx] >= value[this.tableIdx + 1]) {
					if (value[this.tableIdx] >= value[this.tableIdx + 2]) {
						value = this.horses[this.tableIdx];
					} else {
						value = this.horses[this.tableIdx + 2];
					}
				} else {
					value = this.horses[this.tableIdx + 1];
				}
			}
			if (this.pick == 4) {
				if (
					value[this.tableIdx] >= value[this.tableIdx + 1] &&
					value[this.tableIdx] >= value[this.tableIdx + 2] &&
					value[this.tableIdx] >= value[this.tableIdx + 3]
				) {
					value = this.horses[this.tableIdx];
				} else if (
					value[this.tableIdx + 1] >= value[this.tableIdx + 2] &&
					value[this.tableIdx + 1] >= value[this.tableIdx + 3]
				) {
					value = this.horses[this.tableIdx + 1];
				} else if (value[this.tableIdx + 2] >= value[this.tableIdx + 3]) {
					value = this.horses[this.tableIdx + 2];
				} else {
					value = this.horses[this.tableIdx + 3];
				}
			}
			return value;
		},
		canales() {
			let v = this.channels;
			let c = v.sort((a, b) => {
				return parseInt(a.name.split(" ")[1]) - parseInt(b.name.split(" ")[1]);
			});
			c.map(e=>{
				if (e.name.split(" ")[0].toLowerCase() != 'canal') {
					c.pop();
				}
			})
			// for (let i = 0; i < 1; i++) {
			// 	console.log(c);
			// 	c.pop();
			// }

			return c;
		},
		typeGame() {
			let game = this.selectedGameType.toLowerCase();

			if (this.selectedGameType == "G/P") {
				return "GP";
			} else if (this.selectedGameType == "G/P/S") {
				return "GPS";
			} else {
				return game.charAt(0).toUpperCase() + game.slice(1);
			}
		},

		hipodromos() {
			let array;
			let abiertas = [];
			let corriendo = [];
			let cerradas = [];
			let otras = [];

			array = this.events.filter((e) => {
				return e.tipo == "HIPODROMO";
			});

			array.filter((e) => {
				if (e.estatus == "ABIERTA") {
					abiertas.push(e);
				} else if (e.estatus == "CORRIENDO") {
					corriendo.push(e);
				} else if (e.estatus == "CERRADA") {
					cerradas.push(e);
				} else {
					otras.push(e);
				}
			});
			return abiertas.concat(corriendo, cerradas, otras);
		},
		galgos() {
			let array;
			let abiertas = [];
			let corriendo = [];
			let cerradas = [];
			let otras = [];

			array = this.events.filter((e) => {
				return e.tipo == "GALGODROMO";
			});

			array.filter((e) => {
				if (e.estatus == "ABIERTA") {
					abiertas.push(e);
				} else if (e.estatus == "CORRIENDO") {
					corriendo.push(e);
				} else if (e.estatus == "CERRADA") {
					cerradas.push(e);
				} else {
					otras.push(e);
				}
			});
			return abiertas.concat(corriendo, cerradas, otras);
		},
		carretas() {
			let array;
			let abiertas = [];
			let corriendo = [];
			let cerradas = [];
			let otras = [];

			array = this.events.filter((e) => {
				return e.tipo == "CARRETA";
			});

			array.filter((e) => {
				if (e.estatus == "ABIERTA") {
					abiertas.push(e);
				} else if (e.estatus == "CORRIENDO") {
					corriendo.push(e);
				} else if (e.estatus == "CERRADA") {
					cerradas.push(e);
				} else {
					otras.push(e);
				}
			});
			return abiertas.concat(corriendo, cerradas, otras);
		},
		GPS() {
			//inicializamos variable con las selecciones realizadas
			//de los caballos que pueden estar en 1,2,3 y 4 lugar
			let jugada = this.jugada;
			let jugada2 = this.jugada2;
			let jugada3 = this.jugada3;
			let jugada4 = this.jugada4;

			let totalcombinaciones = [];
			let s = [];
			jugada.forEach((v, idx) => {
				if (idx == 0) {
					s.push(v);
				} else {
					s.push("-" + v);
				}
			});

			jugada2.forEach((v, idx) => {
				if (idx == 0) {
					s.push("/" + v);
				} else {
					s.push("-" + v);
				}
			});
			jugada3.forEach((v, idx) => {
				if (idx == 0) {
					s.push("/" + v);
				} else {
					s.push("-" + v);
				}
			});
			jugada4.forEach((v, idx) => {
				if (idx == 0) {
					s.push("/" + v);
				} else {
					s.push("-" + v);
				}
			});

			if (this.selectedGameType == "EXACTA") {
				for (let i = 0; i < jugada.length; i++) {
					//iteramos la primera matriz segun
					//la cantidad de datos que posee
					for (let j = 0; j < jugada2.length; j++) {
						if (jugada2[j] == jugada[i]) continue;
						totalcombinaciones.push(`${jugada[i]}-${jugada2[j]}`);
					}
				}

				return {
					monto: totalcombinaciones.length * this.selectedAmout,
					comb: s.join(""),
				};
			} else if (this.selectedGameType == "TRIFECTA") {
				for (let i = 0; i < jugada.length; i++) {
					//iteramos la primera matriz segun
					//la cantidad de datos que posee

					for (let j = 0; j < jugada2.length; j++) {
						//realizamos la comparación de si posee dentro
						//del arreglo un valor igual al segundo arreglo
						//si lo posee va a continuar iterando con la otra funcion for

						if (jugada[i] == jugada2[j]) continue;

						//iteramos el tercer arreglo

						for (let k = 0; k < jugada3.length; k++) {
							//hacemos la comprobacion
							//Si posee datos iguales entre el arreglo de jugada3 y el arreglo de jugada continuara el ciclo
							//si posee datos iguales entre el arreglo de la carrera 3 y el arreglo de la jugada2 continuara
							//el ciclo y agregara en un arreglo vacio, las combinaciones realizadas

							if (jugada3[k] == jugada[i] || jugada3[k] == jugada2[j]) continue;

							totalcombinaciones.push(
								`${jugada[i]}-${jugada2[j]}-${jugada3[k]}`
							);
						}
					}
				}
				return {
					monto: totalcombinaciones.length * this.selectedAmout,
					comb: s.join(""),
				};
			} else if (this.selectedGameType == "SUPERFECTA") {
				for (let i = 0; i < jugada.length; i++) {
					//iteramos la primera matriz segun
					//la cantidad de datos que posee

					for (let j = 0; j < jugada2.length; j++) {
						//realizamos la comparación de si posee dentro
						//del arreglo un valor igual al segundo arreglo
						//si lo posee va a continuar iterando con la otra funcion for

						if (jugada[i] == jugada2[j]) continue;

						//iteramos el tercer arreglo

						for (let k = 0; k < jugada3.length; k++) {
							//hacemos la comprobacion
							//Si posee datos iguales entre el arreglo de jugada3 y el arreglo de jugada continuara el ciclo
							//si posee datos iguales entre el arreglo de la carrera 3 y el arreglo de la jugada2 continuara
							//el ciclo y agregara en un arreglo vacio, las combinaciones realizadas

							if (jugada3[k] == jugada[i] || jugada3[k] == jugada2[j]) continue;

							for (let l = 0; l < jugada4.length; l++) {
								//hacemos la comprobacion
								//Si posee datos iguales entre el arreglo de jugada4 y el arreglo de jugada continuara el ciclo
								//si posee datos iguales entre el arreglo de la carrera 4 y el arreglo de la jugada2 continuara
								//si posee datos iguales entre el arreglo de la carrera 4 y el arreglo de la jugada3 continuara
								//el ciclo y agregara en un arreglo vacio, las combinaciones realizadas

								if (
									jugada4[l] == jugada[i] ||
									jugada4[l] == jugada2[j] ||
									jugada4[l] == jugada3[k]
								)
									continue;

								totalcombinaciones.push(
									`${jugada[i]}-${jugada2[j]}-${jugada3[k]}-${jugada4[l]}`
								);
							}
						}
					}
				}

				return {
					monto: totalcombinaciones.length * this.selectedAmout,
					comb: s.join(""),
				};
			} else if (this.selectedGameType == "G/P") {
				return {
					monto: jugada.length * this.selectedAmout * 2,
					comb: jugada.join("-"),
				};
			} else if (this.selectedGameType == "G/P/S") {
				return {
					monto: jugada.length * this.selectedAmout * 3,
					comb: jugada.join("-"),
				};
			} else if (this.selectedGameType == "PICK 2") {
				return {
					monto: jugada.length * jugada2.length * this.selectedAmout,
					comb: jugada.join("-") + "/" + jugada2.join("-"),
				};
			} else if (this.selectedGameType == "PICK 3") {
				return {
					monto:
						jugada.length *
						jugada2.length *
						jugada3.length *
						this.selectedAmout,
					comb:
						jugada.join("-") +
						"/" +
						jugada2.join("-") +
						"/" +
						jugada3.join("-"),
				};
			} else if (this.selectedGameType == "PICK 4") {
				return {
					monto:
						jugada.length *
						jugada2.length *
						jugada3.length *
						jugada4.length *
						this.selectedAmout,
					comb:
						jugada.join("-") +
						"/" +
						jugada2.join("-") +
						"/" +
						jugada3.join("-") +
						"/" +
						jugada4.join("-"),
				};
			} else {
				return {
					monto: jugada.length * this.selectedAmout,
					comb: jugada.join("-"),
				};
			}
		},
	},
	last() {
		let idx = this.events.length;
		let value = false;
		if (this.events[idx].idhipodromo == this.selectedEvent.idhipodromo) {
			value = true;
		}
		return value;
	},
};
</script>

<style>
.select-event {
	background-image: linear-gradient(0deg, #1156b0 0%, #00afef 100%);
	border-top-left-radius: 16px !important;
	border-bottom-right-radius: 16px !important;
}

.round {
	position: relative;
}

.round label {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	cursor: pointer;
	height: 28px;
	left: -4px;
	position: absolute;
	top: -4px;
	width: 28px;
}
.round.todo label:after {
	border: none !important;
}
.round label:after {
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	content: "";
	height: 6px;
	left: 7px;
	opacity: 0;
	position: absolute;
	top: 8px;
	transform: rotate(-45deg);
	width: 12px;
}
/* @media (min-width: 1920px) {
	.round label {
		left: 38px;
	}
} */

.round input[type="checkbox"] {
	visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
	background-color: #66bb6a !important;
	border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
	opacity: 1;
}
.t-container {
	margin: 0 12px;
}
.ticket-title {
	background-image: linear-gradient(0deg, #008f51 0%, #2dce89 100%);
	height: 40px;
	border-radius: 4px;
}
.ticket {
	background: rgb(255, 255, 255);
	padding: 0.7rem 1rem;
	border-top-left-radius: 16px;
	border-top-right-radius: 2px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 16px;
	transition: all 0.5s !important;
	/* height: 4.092vw; */
}
.channel-title {
	height: 52px;
	background-image: linear-gradient(0deg, firebrick 0%, #df3b35 100%);
	border-top-left-radius: 2px;
	border-top-right-radius: 16px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 2px;
}
.channel-item {
	width: 4rem;
	height: 4rem;
	background-image: transparent;
	border: 2px solid red;
	border-radius: 50%;
}
.channel-content {
	background: #fff;
	border-top-left-radius: 16px !important;
	border-bottom-right-radius: 16px !important;
}
.t-bag .v-badge__badge::after {
	border-color: #ffffff !important;
	border-width: 1.5px !important;
	transform: scale(1) !important;
}
@media screen and (max-width: 992px) {
	.fc-widget-small {
		bottom: 60px !important;
	}
}
</style>