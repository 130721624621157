import Vue from 'vue'
import VueRouter from 'vue-router'
// import Index from '../views/Index.vue'
// import Promociones from '../views/Promociones.vue'
import Bet from '../views/Bet.vue'
import Results from '../views/Results.vue'
import Profile from '../views/Profile.vue'
import Dashboard from '../views/profile/Dashboard.vue'
import WinningTickets from '../views/profile/WinningTickets.vue'
import Operations from '../views/profile/Operations.vue'
import Plays from '../views/profile/Plays.vue'
import Accounts from '../views/profile/Accounts.vue'
import Cartera from '../views/profile/Cartera.vue'
import Withdrawals from '../views/profile/Withdrawals.vue'
import Recharges from '../views/profile/Recharges.vue'
import Supportick from '../views/profile/Supportick.vue'

// import GameType from '../views/GameType.vue'
import Casino from '../views/Casino.vue'
import OpenGame from '../views/OpenGame.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/terminos',
    name: 'Terminos y condiciones',
    component: () => import('../views/Terminos.vue')
  },
  {
    path: '/promociones',
    name: 'Promociones',
    component: () => import('../views/Promociones.vue')
  },
  {
    path: '/gametype',
    name: 'Tipo de juego',
    component: () => import('../views/GameType.vue')
  },
  {
    path: '/casino',
    name: 'Juegos',
    component: Casino
  },
  {
    path: '/game/:name/:code',
    name: 'Juego',
    component: OpenGame
  },
  {
    path: '/bet',
    name: 'Bet',
    component: Bet
  },
  {
    path: '/channels',
    name: 'Channels',
    component: Bet
  },
  {
    path: '/results',
    name: 'Results',
    component: Results
  },
  {
    path: '/profile',
    component: Profile,
    children: [
      {
        path: '',
        name: 'Perfil',
        component: Dashboard
      },
      {
        path: '/rewards',
        name: 'Tickets premiados',
        component: WinningTickets
      },
      {
        path: '/operations',
        name: 'Operaciones',
        component: Operations
      },
      {
        path: '/plays',
        name: 'Jugadas',
        component: Plays
      },
      {
        path: '/accounts',
        name: 'Mis cuentas',
        component: Accounts,
      },
      {
        path: '/accounts/:idx/:id',
        name: 'Cartera',
        component: Cartera,
      },
      {
        path: '/withdrawals',
        name: 'Hitorrial de retiro',
        component: Withdrawals
      },
      {
        path: '/recharges',
        name: 'Hitorrial de recargas',
        component: Recharges
      },
      {
        path: '/supportick',
        name: 'Supportick',
        component: Supportick
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
