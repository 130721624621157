export default {
  fechaDeAyer() {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let ayer = new Date(hoy.getTime() - DIA_EN_MILISEGUNDOS);
    var day = ayer.getDate();
    var month = ayer.getMonth() + 1;
    var year = ayer.getFullYear();
    return `${year}-${month < 10 ? '0' + month : month}-${day}`;
  },
  today() {

    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var hour = date.getHours()
    

    return hour < 4 ? this.fechaDeAyer() : `${year}-${month < 10 ? '0' + month : month}-${day}` ;
  }
}