import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueTelInput from 'vue-tel-input'
import VueGtag from "vue-gtag";
import axios from 'axios'
import VueFreshchat from 'vue-freshchat';




(async function () {

  axios.defaults.baseURL = "https://apitr.xyz/onlineapi/api/"

  let dominio
  let id

  let domName = document.location.hostname;
  if (domName != "localhost") {
    dominio = domName;
  } else {
    dominio = "trackandraces.com";
  }
  // console.log(dominio);

  id = await axios.post('https://apitr.xyz/onlineapi/api/dominio', { dominio: dominio, analytics: true })
  Vue.use(VueGtag, {
    config: {
      id: id.data.id_analytics
    },
  }, router)
  // console.log(id.data.id_analytics);


  Vue.use(VueTelInput)
  if (dominio == "trackandraces.com" || dominio == "www.trackandraces.com") {
    Vue.use(VueFreshchat, { appToken: 'ac46045e-087b-4989-b190-4ac0ad998f86' });
  }


  Vue.config.productionTip = true


  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})();