<template>
	<div>
		<v-overlay :value="overlay" style="height:100vh">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<iframe :src="gameUrl" style="width:100%; height: calc(100vh - 95px);" frameborder="0"></iframe>
	</div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
	data() {
		return {
			gameUrl: null,
			overlay: true,
			isMobile: 0
		};
	},
	watch: {
		idmoneda() {
			this.overlay = true;
			this.getGame();
		}
	},
	beforeDestroy() {
		if (typeof window === "undefined") return;
		window.removeEventListener("resize", this.onResize, { passive: true });
	},
	mounted() {
		this.isMobile = window.innerWidth < 600 ? 1 : 0;
		this.onResize();
		window.addEventListener("resize", this.onResize, { passive: true });
		axios({
			method: "post",
			url: `details`
		}).then(response => {
			this.$store.state.nombre = response.data.success.name;
		});
		this.getSaldo();
		if (document.getElementById("fc_frame")) {
			document.getElementById("fc_frame").style.display = "none";
		}
		this.idpos = sessionStorage.getItem("idpos");
		this.getGame();
	},
	updated() {
		if (document.getElementById("fc_frame")) {
			document.getElementById("fc_frame").style.display = "none";
		}
	},
	methods: {
		...mapActions(["getSaldo"]),
		onResize() {
			this.isMobile = window.innerWidth < 600 ? 1 : 0;
		},
		getGame() {
			axios
				.get(
					`https://api.dotslotsuite.com/api/game?client_id=38&client_secret=9307ca38-ad1c-47fa-8c25-f70aad7&user=${
						this.idpos
					}&username=username&currency=${this.moneda}&code=${
						this.$route.params.code
					}&return_url=https://example.com&language=es_ES&mobile=${
						this.isMobile
					}&extra_data={"game":"${this.$route.params.name.replace(
						"-",
						" "
					)}","idsb":"${this.idsb}","idmoneda":"${
						this.idmoneda
					}"}&initial_balance=${this.currentBalance}`
				)
				.then(e => {
					this.gameUrl = e.data.data.url;
					this.overlay = false;
				});
		}
	},
	computed: {
		moneda() {
			return this.$store.state.moneda;
		},
		idmoneda() {
			return this.$store.state.idmoneda;
		},
		idsb() {
			return this.$store.state.idsb;
		},
		currentBalance() {
			return this.$store.state.currentBalance;
		},
		idpos: {
			get() {
				return this.$store.state.idpos;
			},
			set(e) {
				this.$store.state.idpos = e;
			}
		}
	}
};
</script>

<style>
</style>
