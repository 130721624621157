<template>
	<div>
		<v-card :color="colors.sidebar">
			<v-card-text class="py-0 px-0">
				<v-card-title class="white--text py-2 d-flex align-center">
					<b class="mt-1" style="font-weight:500;font-size:14px" v-if="size > 960">MI CARTERA {{saldos[$route.params.idx].simbolo}}({{saldos[$route.params.idx].moneda}})</b>
					<v-btn
						:color="colors.primary"
						dark
						class="ml-auto mb-2 mb-lg-0"
						:block="size<960"
						@click="dialogAccount = true;"
					>
          
						<v-icon>mdi-plus</v-icon>AÑADIR CUENTA
					</v-btn>
					<v-text-field
						:color="colors.perfil"
						class="field-date ml-2"
						:style="size > 960 ? 'margin:0px 0 0;padding:0; max-width:212px':''"
						background-color="#f5f5f5"
						single-line
						dense
						solo
						flat
						hide-details
						v-model="search"
						append-icon="mdi-magnify"
						label="Buscar"
					></v-text-field>
				</v-card-title>
				<v-divider></v-divider>
				<v-data-table
					:search="search"
					class="horse"
					:loading="loading"
					:height="size < 940?'calc(100vh - 324px)':'calc(100vh - 210px)'"
					fixed-header
					:headers="headers"
					:items="accounts"
					:items-per-page="10"
				>
					<template v-slot:item.tipo="{ item }">
						<b style="font-weight:500" class="text-capitalize">{{item.tipo}}</b>
					</template>

					<template v-slot:item.image="{ item }">
						<v-img
							class
							contain
							:aspect-ratio="item.tipo == 'Transferencia Bancaria'?2:4"
							width="80px"
							:src="item.imagen"
						></v-img>
					</template>

					<!-- <template v-slot:item.actions="{ item }">
						<v-icon small class="mr-2" @click="editAcc(item)">mdi-pencil</v-icon>
						<v-icon small color="red" :disabled="loading" @click="confirm(item.id)">mdi-delete</v-icon>
					</template>-->
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-dialog v-model="dialogAccount" max-width="840" scrollable>
			<v-card flat>
				<v-card-text>
					<v-card-title class="px-0 body-1">
						<b style="font-weight:500" class="color">Selecione el tipo de cuenta</b>
					</v-card-title>
					<v-row>
						<v-col cols="12" md="4" v-for="(type,idx) in accountsTypes" :key="type.id">
							<v-card class="mx-auto" outlined @click="selectAccountType(type,idx)">
								<v-progress-circular
									class="d-flex mx-auto mt-11"
									v-if="type[`prog${idx}`]"
									indeterminate
									color="black"
									:width="3"
								></v-progress-circular>
								<v-img v-else class="mt-4" contain :aspect-ratio="size < 940?7:4" :src="type.imagen"></v-img>
								<v-card-subtitle class="mx-auto pb-2">{{type.tipo}}</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-spacer></v-spacer>
					<v-btn text color="#eb262c" @click="dialogAccount = false">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
			scrollable
			v-model="dialogAccountForm"
			:max-width="selectedAccountType&&selectedAccountType.tipo == 'Transferencia Bancaria'? 600 :360"
		>
			<form id="account" @submit="formAccount">
				<v-card flat v-if="selectedAccountType">
					<v-card-text>
						<v-card-title class="d-flex pa-0 mb-2 body-1 color" v-if="edit">
							<b
								style="font-weight:500"
								v-if="selectedAccountType.tipo != 'Transferencia Bancaria'"
							>Editar cuenta de {{selectedAccountType.tipo}}</b>
						</v-card-title>
						<v-card-title class="d-flex pa-0 mb-2 body-1 color" v-else>
							<b
								style="font-weight:500"
								v-if="selectedAccountType.tipo != 'Transferencia Bancaria'"
							>Nueva cuenta de {{selectedAccountType.tipo}}</b>
						</v-card-title>
						<v-img
							class="mb-2"
							contain
							:aspect-ratio="accountsTypes.tipo == 'Transferencia Bancaria'? 8:6"
							:src="selectedAccountType.imagen"
						></v-img>
						<v-row>
							<template v-for="(item,idx) in accountInputs.campos">
								<v-col
									v-if="item != 'telefono'"
									:key="idx+'a'"
									cols="12"
									:lg="accountInputs.campos.length > 4?item == 'n_cuenta'?12:6:12"
								>
									<v-text-field
										:color="colors.perfil"
										v-if="idx == 0?dialogAccountForm:true"
										v-model="inputs[item]"
										:autofocus="idx == 0"
										:label="label(item)"
										single-line
										dense
										solo
										required
										flat
										background-color="blue-grey lighten-5"
										hide-details
										:type="item == 'email'?'email':'text'"
									></v-text-field>
								</v-col>
								<v-col
									v-if="item == 'telefono'"
									:key="idx+'b'"
									cols="12"
									:lg="accountInputs.campos.length > 4?item == 'n_cuenta'?12:6:12"
								>
									<vue-tel-input
										ref="tel"
										:autofocus="true"
										@input="onInput"
										:required="true"
										:preferred-countries="['ve', 'pe', 'co']"
										class="phone"
										placeholder="Teléfono"
										v-model="inputs[item]"
									></vue-tel-input>
								</v-col>
							</template>
							<!-- <v-col :cols="12" v-if="selectedAccountType.tipo == 'Transferencia Bancaria'">
								<v-select
									:color="colors.perfil"
									v-model="selectedAccountType.id_pais"
									append-icon="mdi-map"
									solo
									dense
									flat
									required
									background-color="blue-grey lighten-5"
									label="Pais"
									single-line
									hide-details
									:items="countries"
									item-value="id"
									item-text="paisnombre"
									class="mt-0 pt-0"
								></v-select>
							</v-col> -->
							<!-- <v-col :cols="12" :md="selectedAccountType.tipo == 'Transferencia Bancaria'? 6 : 12">
								<v-text-field
									:color="colors.perfil"
									v-if="dialogAccountForm"
									v-model="editedAccount.titular"
									autofocus
									label="Nombre"
									single-line
									dense
									solo
									required
									flat
									background-color="blue-grey lighten-5"
									hide-details
									append-icon="mdi-account-circle"
								></v-text-field>
							</v-col>
							<v-col
								:cols="12"
								:md="selectedAccountType.tipo == 'Transferencia Bancaria'? 6 : 12"
								v-if="selectedAccountType.tipo == 'Transferencia Bancaria'"
							>
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.cedula"
									background-color="blue-grey lighten-5"
									hide-details
									label="DNI"
									single-line
									dense
									required
									solo
									flat
									append-icon="mdi-card-account-details-outline"
								></v-text-field>
							</v-col>
							<v-col
								v-if="selectedAccountType.id != 9"
								:cols="12"
								:md="selectedAccountType.tipo == 'Transferencia Bancaria'? 6 : 12"
							>
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.email"
									background-color="blue-grey lighten-5"
									hide-details
									label="Correo electrónico"
									single-line
									dense
									solo
									flat
									required
									append-icon="mdi-email"
									type="email"
								></v-text-field>
							</v-col>
							<v-col cols="6" v-if="selectedAccountType.tipo == 'Transferencia Bancaria' ">
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.banco"
									autofocus
									:label="selectedAccountType.id == 1? 'Nombre del banco asociado':'Banco'"
									single-line
									dense
									solo
									flat
									required
									background-color="blue-grey lighten-5"
									hide-details
									append-icon="mdi-bank"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col
								cols="12"
								:md="selectedAccountType.id == 1? 12 :6"
								v-if=" selectedAccountType.id == 1 "
							>
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.banco"
									autofocus
									:label="selectedAccountType.id == 1? 'Nombre del banco asociado':'Banco'"
									single-line
									dense
									solo
									flat
									required
									background-color="blue-grey lighten-5"
									hide-details
									append-icon="mdi-bank"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								v-if="selectedAccountType.tipo == 'Transferencia Bancaria' || selectedAccountType.id == 4"
							>
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.n_cuenta"
									background-color="blue-grey lighten-5"
									hide-details
									:label="selectedAccountType.id == 4? 'Cuenta':'Numero de cuenta'"
									single-line
									dense
									solo
									flat
									required
									append-icon="mdi-credit-card-outline"
								></v-text-field>
							</v-col>
							<v-col cols="6" v-if="selectedAccountType.tipo == 'Transferencia Bancaria'">
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.iban"
									background-color="blue-grey lighten-5"
									hide-details
									label="IBAN"
									single-line
									dense
									solo
									flat
									required
									append-icon="mdi-credit-card-outline"
								></v-text-field>
							</v-col>
							<v-col cols="6" v-if="selectedAccountType.tipo == 'Transferencia Bancaria'">
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.swift"
									background-color="blue-grey lighten-5"
									hide-details
									label="SWIFT"
									single-line
									dense
									solo
									flat
									required
									append-icon="mdi-credit-card-outline"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6" v-if="selectedAccountType.tipo == 'Transferencia Bancaria'">
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.direccion"
									background-color="blue-grey lighten-5"
									hide-details
									label="Dirrección"
									single-line
									dense
									solo
									flat
									required
									append-icon="mdi-map-marker"
								></v-text-field>
							</v-col>
							<v-col
								:cols="selectedAccountType.id == 9?12:6"
								v-if="selectedAccountType.tipo == 'Transferencia Bancaria' || selectedAccountType.id == 9"
							>
								<v-text-field
									:color="colors.perfil"
									v-model="editedAccount.telefono"
									background-color="blue-grey lighten-5"
									hide-details
									label="Teléfono"
									single-line
									dense
									solo
									flat
									required
									append-icon="mdi-phone"
								></v-text-field>
							</v-col>
							<v-col :cols="12" v-if="selectedAccountType.tipo == 'Transferencia Bancaria'">
								<v-select
									:color="colors.perfil"
									v-model="selectedAccountType.id_pais"
									append-icon="mdi-map"
									solo
									dense
									flat
									required
									background-color="blue-grey lighten-5"
									label="Pais"
									single-line
									hide-details
									:items="countries"
									item-value="id"
									item-text="paisnombre"
									class="mt-0 pt-0"
								></v-select>
							</v-col>-->
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>

						<v-btn color="#eb262c" :disabled="loading" text @click="dialogAccountForm = false">Cancelar</v-btn>

						<v-btn color="#0eb3ef" :loading="loading" :disabled="loading" text type="submit">Guardar</v-btn>
					</v-card-actions>
				</v-card>
			</form>
		</v-dialog>
		<Confirm
			title="¿ELIMINAR CUENTA?"
			text="La cuenta se eliminara, esta acción no se puede revertir."
			:loading="loading"
			:open="dialog"
			@ok="deleteAcc"
			@close="dialog = false"
		/>
	</div>
</template>

<script>
import axios from "axios";
import Confirm from "../../components/Confirm.vue";

export default {
	components: {
		Confirm,
	},
	data() {
		return {
			loading: false,
			dialog: false,
			accLoading: false,
			dialogAccount: false,
			dialogAccountForm: false,
			accounts: [],
			search: "",
			headers: [
				{ text: "", value: "image" },
				{ text: "CUENTA", value: "tipo" },
				{ text: "NOMBRE", value: "titular" },
				{ text: "EMAIL", value: "email" },
				{ text: "PAIS", value: "paisnombre" },
				{ text: "", value: "actions", align: "center" },
			],
			id: null,
			edit: false,
			accountsTypes: [],
			selectedAccountType: null,
			countries: [],
			accountInputs: {},
			inputs: {},
			phone: "",
			editedAccount: {
				titular: "",
				cedula: "",
				banco: "",
				email: "",
				n_cuenta: "",
				iban: "",
				swift: "",
				direccion: "",
				telefono: "",
				paisnombre: "",
			},
			defaultAccount: {
				titular: "",
				cedula: "",
				banco: "",
				email: "",
				n_cuenta: "",
				iban: "",
				swift: "",
				direccion: "",
				telefono: "",
				paisnombre: "",
			},
			code: "",
			tel: "",
		};
	},
	watch: {
		dialogAccountForm(val) {
			val || this.close();
			if (val) {
				if (Object.keys(this.inputs).indexOf("telefono") == 0) {
					setTimeout(() => {
						this.$refs.tel[0].$el.children[1].focus();
					}, 1);
				}
			}
		},
	},
	mounted() {
		this.getAccounts();
		this.getAccountsTypes();
		this.getCountries();
	},
	methods: {
		onInput(formattedNumber, { number }) {
			this.tel = number.significant;
			this.inputs.telefono = number.international;
		},
		label(item) {
			if (item == "n_cuenta") {
				return "Numero de cuenta";
			} else if (item == "titular") {
				return "Nombre";
			} else {
				return item[0].toUpperCase() + item.slice(1);
			}
		},
		getCountries() {
			axios({
				method: "get",
				url: `/pais`,
			}).then((response) => {
				this.countries = response.data.pais;
			});
		},
		formAccount(e) {
			e.preventDefault();
			// console.log(this.inputs);

			this.saveAccount();
		},
		getAccounts() {
			this.loading = true;
			axios({
				method: "get",
        url: `/new_miscuentas`,
        params:{
          idpais: this.$route.params.id
        }
			}).then((response) => {

				this.accounts = response.data.cuentas;
				this.loading = false;
			});
		},
		getAccountsInputs(value, idx) {
			axios({
				method: "get",
				url: `/campo_tipo_cuenta`,
				params: {
					id: this.edit ? value.id_tipo_cuenta : value.id,
				},
			}).then((response) => {
				this.accountInputs = response.data;
				if (this.edit == false) {
					this.inputs = {};
					this.inputs = response.data.campos.reduce(
						(a, b) => ((a[b] = ""), a),
						{}
					);
				}
				this.setInputs();
				if (this.edit == false) {
					this.inputs.titular = this.nombre.replace(/\b\w/g, (l) =>
						l.toUpperCase()
					);
					value[`prog${idx}`] = false;
				}
			});
		},
		setInputs() {
			this.dialogAccount = false;
			this.dialogAccountForm = true;
		},
		getAccountsTypes() {
			axios({
				method: "get",
        url: `/new_tipo_cuenta`,
        params:{
          idpais: this.$route.params.id
        }
			}).then((response) => {
				response.data.tipo_cuenta.map((value, idx) => {
					let pro = "prog" + idx;
					value[pro] = false;
					this.accountsTypes.push(value);
				});
			});
		},
		selectAccountType(value, idx) {
			value[`prog${idx}`] = true;

			this.selectedAccountType = value;
			this.getAccountsInputs(value, idx);
		},
		saveAccount() {
			this.loading = true;
			if (this.inputs && this.inputs.telefono) {
				let split = this.inputs.telefono.split("+")[1];
				let area = split.split(" ")[0];
				this.inputs.telefono = area + "-" + this.tel;
			}
			let datos = this.inputs;
			datos.id_tipo_cuenta = this.selectedAccountType.id;
			datos.id_pais = this.selectedAccountType.id_pais;
			datos.n_cuenta = this.inputs[this.accountInputs.default];
      datos.id_pais =  this.$route.params.id

			if (this.edit) {
				axios({
					method: "post",
					url: `/editar_cuenta`,
					data: datos,
				}).then((response) => {
					this.getAccounts();
					this.selectedAccountType = null;
					this.$store.state.snack = true;
					this.$store.state.msg = response.data.mensaje;
					this.dialogAccountForm = false;
					this.loading = false;
					this.id = null;
					this.edit = false;
				});
			} else {
				axios({
					method: "post",
					url: `/guardar_cuenta`,
					data: datos,
				}).then((response) => {
					this.getAccounts();
					this.selectedAccountType = null;
					this.$store.state.snack = true;
					this.$store.state.msg = response.data.mensaje;
					this.dialogAccountForm = false;
					this.loading = false;
					this.id = null;
				});
			}
		},
		editAcc(item) {
			this.edit = true;
			this.selectedAccountType = item;
			this.selectedAccountType.telefono = "+" + item.telefono.replace("-", " ");
			this.inputs = Object.assign({}, item);
			this.getAccountsInputs(item);

			// this.dialogAccountForm = true;
		},
		deleteAcc() {
			this.loading = true;
			axios({
				method: "post",
				url: `/eliminar_cuenta`,
				data: {
					id: this.id,
				},
			}).then((response) => {
				if (response.data.mensaje == "Cuenta Eliminada con exito") {
					this.getAccounts();
				}
				this.$store.state.snack = true;
				this.$store.state.msg = response.data.mensaje;
				this.dialog = false;
				this.loading = false;
				this.id = null;
			});
		},
		confirm(id) {
			this.id = id;
			this.dialog = true;
		},
		close() {
			this.dialogAccountForm = false;
			this.$nextTick(() => {
				this.editedAccount = Object.assign({}, this.defaultAccount);
				this.edit = false;
			});
		},
	},
	computed: {
		saldos() {
			return this.$store.state.saldos;
		},
		accTypeId() {
			return this.accountsTypes.filter((item) => {
				return item.tipo == this.selectedAccountType.tipo ? item.id : "";
			});
		},
		size() {
			return this.$store.state.windowSize.x;
		},
		colors() {
			return this.$store.state.colors;
		},
		nombre() {
			return this.$store.state.nombre;
		},
	},
};
</script>

<style>
</style>